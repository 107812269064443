import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { onPercentExcessToggleChange } from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsActions'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import FlexBox from 'Scenes/Components/FlexBox'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'

const AdminFunctionStringToggles: FC = () => {
	const dispatch = useDispatch()
	const { usePercentExcess } = useSelector((state: RootStateOrAny) => {
		return state.user
	})

	return (
		<CastorForm
			formTitle={'Function Strings Toggles'}
			content={
				<div>
					<FlexBox alignItems="center">
						<CastorSwitch
							onChange={(e: any) => {
								dispatch(onPercentExcessToggleChange(!usePercentExcess))
							}}
							checked={usePercentExcess}
						/>
						<div>Percent of excess material calculation</div>
					</FlexBox>
				</div>
			}
			style={{ maxWidth: 'unset' }}
		/>
	)
}

export default HideForRoleHOC(
	WithFeatureToggleHOC(
		memo(AdminFunctionStringToggles),
		FeatureComponentId.FUNCTION_STRING_TOGGLES_SETTINGS
	),
	[UserRole.USER_MANAGER]
)
