import { cloneDeep, sortBy } from 'lodash'
import isNull from 'lodash/isNull'

import {
	ADMIN_MATERIAL_ADDED,
	ADMIN_MATERIAL_DELETED,
	ADMIN_PERCENT_EXCESS_TOGGLED,
	ADMIN_PRINTER_DELETED,
	ADMIN_PRINTER_MATERIAL_DELETED,
	ADMIN_PRINTER_MATERIAL_UPDATED,
	ADMIN_SETINGS_PRIORITIES_TOGGLED,
	CHANGE_USER_MATERIAL_NAME_MAPPING,
	CO2_USER_SETTINGS_CARBON_CHANGED,
	CO2_USER_SETTINGS_CARBON_CREDIT_COST,
	CO2_USER_SETTINGS_DATA_LOADING,
	CO2_USER_SETTINGS_DISPOSE,
	CO2_USER_SETTINGS_HOLD,
	CO2_USER_SETTINGS_PU,
	COST_AND_LEAD_TBL_DATA_UPDATED,
	CREATE_USER_MATERIAL_NAMES_MAPPING,
	DEFAULT_SCENARIO_CHANGED,
	FATCH_USER_MATERIAL_NAMES_MAPPING,
	FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
	GOT_LOGIN_DATA,
	GOT_MATERIAL_TECHNOLOGIES_DATA,
	LOGIN_PENDING,
	LOGIN_STATE_CHANGED,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	NEW_ORGANIZATION_ADDED,
	NEW_PAGE_VISITED,
	NEW_PRINTER_ADDED_TO_USER,
	NEW_PRINTER_MATERIAL_ADDED,
	ORGANIZATION_INFO_UPDATED,
	POST_PROCESS_UPDATED,
	PRINTER_ADDED_TO_USER,
	PRINTER_REMOVED_FROM_USER,
	REMOVE_USER_MATERIAL_NAMES_MAPPING,
	RESET_AUTH_STATE,
	START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_SHOW_TOUR,
	UPDATE_TOUR_FAILED,
	UPDATE_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_USER_ONBOARDING_STATE,
	USER_CUSTOM_SETTINGS_UPDATED,
	USER_DETAIL_CHANGED,
	USER_FILTERS_CHANGE,
	USER_FILTERS_TOGGLES_UPDATED,
	USER_ID_UPDATE,
	USER_LANGUAGE_CHANGED,
	USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE,
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
	USER_PRINTER_REMOVED_FROM_USER,
	USER_UNITS_TYPE_SELECTED
} from '../global actions/types'
import { checkIfDisabled } from '../Scenes/Home/Customize/CustomizeMaterialNamesMapping/CastorMaterialNamesMappingService'
import {
	ADMIN_ROLES,
	defaultAllowedFileTypes,
	defaultMaxAllowedUploadParts,
	defaultMaxAllowedUploadProjects,
	defaultNotSupportedUnitTypeFormats,
	defaultPartsFileSizeLimit,
	defaultSettingScenario,
	defaultUploadFileName,
	defaultUploadUnitType,
	materialTypes
} from '../Services/Constants'
import { FeatureComponentId } from '../Services/models/Features'
import { getUserLanguage } from 'Services/Utils/startupTools'

const addressFeature = [
	{
		componentId: FeatureComponentId.ADDRESS,
		description: '',
		id: 27,
		name: 'address',
		on: process.env.REACT_APP_ONPREM !== 'true'
	},
	{
		componentId: FeatureComponentId.OFFLINE_ADDRESS,
		description: '',
		id: 28,
		name: 'offline_address',
		on: process.env.REACT_APP_ONPREM === 'true'
	}
]

const INITIAL_STATE = {
	loggedIn: false,
	loginPending: false,
	stateUnknown: true,
	user: null,
	roles: [],
	features: [...addressFeature],
	userOnTrial: null,
	materials: [],
	materialTypes: [],
	defaultSettings: {},
	userSettings: {},
	userDetails: {},
	printerTechnologies: {},
	userLanguage: getUserLanguage(),
	userCurrencySign: '$',
	printersFullData: [],
	printerTechnologiesIds: [],
	printingTechnologies: [],
	printers: [],
	userMaterials: [],
	userPrinterMaterials: [],
	userPrinters: [],
	materialCategories: [],
	filters: [],
	userFilters: [],
	priorities: [],
	usePercentExcess: false,
	allOptionalPostProcessesData: [],
	optionalPostProcessesBreakDown: [],
	applications: [],
	printingStandardsList: [],
	acceptedFileTypes: [],
	partsFileSizeLimit: 0,
	notSupportedUnitTypeFormats: [],
	defaultConfigurations: null,
	uploadProjectSelectedRadioButton: null,
	defaultUploadProjectSelectedRadioButton: null,
	defaultUploadProjectMaterialType: materialTypes.plastic,
	filterPartsDefaultSelection: null,
	printerMaterialUniqueNames: [],
	defaultBomFilePath: '',
	defaultMaterialsNameBom: '',
	defaultUserMaterialsNameBom: '',
	defaultGenerativeFilePath: '',
	defaultMetadataFilePath: '',
	threeDViewerURL:
		process.env.REACT_APP_ONPREM === 'true'
			? '../../../../heatmapViewer/index.html'
			: 'https://new-heatmap.s3.amazonaws.com/index.html',
	currentPageName: '',
	pagesVisited: null,
	pages: [],
	userCustomizationSettings: {},
	printIssues: [],
	contactUsEmail: '',
	userProviders: [],
	userMaterialNamesMapping: [],
	doMaterialNameMappingRefresh: false,
	disableMaterialNameMappingSaveAll: true,
	isLoadingMaterial: false,
	defaultProjectScenario: defaultSettingScenario.lowVolume,
	co2PerKW: '',
	puAnnualKgCO2: '',
	puYearsCO2: '',
	disposeFactor: '',
	holdFactor: '',
	carbonCreditCost: '',
	maxAllowedUploadParts: defaultMaxAllowedUploadParts,
	maxAllowedLightUploadParts: defaultMaxAllowedUploadParts,
	allowedCountries: [],
	maxAllowedUploadProjects: defaultMaxAllowedUploadProjects,
	uploadUnitType: defaultUploadUnitType,
	defaultFileName: defaultUploadFileName,
	isLightUser: false,
	defaultProjectScenarios: [],
	defaultProjectScenarioParameters: [],
	partsBundle: '',
	editableFunctionStrings: [],
	carbonLoading: false,
	puAnnualKgCO2Loading: false,
	puAnnualKgCO2ResetLoading: false,
	puYearsCO2Loading: false,
	puYearsCO2ResetLoading: false,
	disposeFactorLoading: false,
	carbonCreditCostLoading: false,
	holdFactorLoading: false,
	disposeFactorResetLoading: false,
	carbonCreditCostResetLoading: false,
	carbonResetLoading: false,
	holdFactorResetLoading: false,
	onboardingCompleted: true,
	isEditingMaterial: false,
	initialUserDefaultMaterial: {},
	quickUploadColumns: [],
	isCommunicationTool: false,
	customPostProcessTemplate: {},
	userSettingsDefaultValues: {},
	organizationDetails: {},
	availableOrganizations: [],
	allOrganizations: [],
	isAdminByRole: false
}

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOGIN_STATE_CHANGED:
			const loggedIn = action.payload
			const resetUserState = loggedIn ? {} : INITIAL_STATE
			return {
				...state,
				...resetUserState,
				stateUnknown: false,
				loggedIn
			}
		case LOGIN_PENDING:
			return { ...state, loginPending: action.payload }
		case NEW_PAGE_VISITED:
			return { ...state, currentPageName: action.payload }
		case GOT_MATERIAL_TECHNOLOGIES_DATA:
			const {
				printerTechnologies,
				printerTechnologiesIds = [],
				printingTechnologies,
				printers = [],
				userMaterials,
				userPrinters,
				userPrinterMaterials,
				printerMaterialUniqueNames
			} = action.payload
			return {
				...state,
				printerTechnologies,
				printerTechnologiesIds,
				printingTechnologies,
				printers,
				userMaterials,
				userPrinters,
				userPrinterMaterials,
				printerMaterialUniqueNames
			}
		case USER_LANGUAGE_CHANGED:
			const { userLanguage } = action.payload
			return { ...state, userLanguage }
		case USER_DETAIL_CHANGED: {
			const {
				userDetails,
				availableOrganizations,
				organizationDetails,
				printerMaterials,
				printerMaterialsSubCategories,
				printerMaterialsCategories,
				materialCategories,
				printersFullData,
				materials
			} = action.payload

			let currentMaterialTypes = []

			if (materials) {
				materials.forEach(material => {
					material.category = material.category.toLowerCase()
					if (!currentMaterialTypes.includes(material.type)) {
						currentMaterialTypes.push(material.type)
					}
				})
			}
			if (printerMaterials) {
				printerMaterials.forEach(printerMaterial => {
					if (!currentMaterialTypes.includes(printerMaterial.type)) {
						currentMaterialTypes.push(printerMaterial.type)
					}
				})
			}

			return {
				...state,
				materials,
				userDetails,
				materialCategories,
				printersFullData,
				printerMaterials: printerMaterials,
				printerMaterialsSubCategories: printerMaterialsSubCategories,
				printerMaterialsCategories: printerMaterialsCategories,
				materialTypes: currentMaterialTypes,
				availableOrganizations:
					availableOrganizations || state.availableOrganizations,
				organizationDetails: organizationDetails || state.organizationDetails
			}
		}
		case GOT_LOGIN_DATA: {
			const payload = action.payload.generalData
			const isLightUser = action.payload.isLightUser
			const materials = payload ? payload.materials : null
			const printerMaterials = payload ? payload.printerMaterials : null
			const printerMaterialsSubCategories = payload
				? payload.printerMaterialsSubCategories
				: null
			const printerMaterialsCategories = payload
				? payload.printerMaterialsCategories
				: null
			const userSettings = payload ? payload.userSettings : {}
			const defaultSettings = payload ? payload.defaultSettings : {}
			const applications = payload ? payload.applications : []
			const userDetails = payload ? payload.userDetails : {}
			const organizationDetails =
				payload?.organizationDetails || state.organizationDetails || {}
			const availableOrganizations = payload?.availableOrganizations || []
			const allOrganizations =
				payload?.allOrganizations || state.allOrganizations || []
			const materialCategories = payload ? payload.materialCategories : []
			const filters = payload ? payload.filters : {}

			const printersFullData = payload ? payload.printersFullData : []
			const priorities = payload ? payload.priorities : {}
			const usePercentExcess = payload?.usePercentExcess || false
			const pages = payload && payload.pages ? payload.pages : {}
			const printIssues =
				payload && payload.printIssues ? payload.printIssues : []
			const acceptedFileTypes =
				payload?.allowedFileTypes || defaultAllowedFileTypes
			const partsFileSizeLimit =
				payload?.partsFileSizeLimit || defaultPartsFileSizeLimit
			const defaultFileName = payload?.defaultFileName || defaultUploadFileName

			const notSupportedUnitTypeFormats =
				payload?.notSupportedUnitTypeFormats ||
				defaultNotSupportedUnitTypeFormats

			const maxAllowedUploadParts =
				payload?.maxAllowedUploadParts || defaultMaxAllowedUploadParts

			const maxAllowedLightUploadParts =
				payload?.maxAllowedLightUploadParts || defaultMaxAllowedUploadParts

			const allowedCountries = payload?.allowedCountries || []

			const maxAllowedUploadProjects =
				payload?.maxAllowedUploadProjects || defaultMaxAllowedUploadProjects

			const uploadUnitType =
				payload?.defaultUploadUnitType || defaultUploadUnitType

			const uploadProjectSelectedRadioButton =
				payload?.uploadProjectSelectedRadioButton || null
			const defaultUploadProjectMaterialType =
				payload?.defaultUploadProjectMaterialType || materialTypes.plastic
			const filterPartsDefaultSelection =
				payload?.filterPartsDefaultSelection || null

			const threeDViewerURL = payload
				? payload.threeDViewerURL || state.threeDViewerURL
				: state.threeDViewerURL
			const userSubscriptionDetails = payload?.userSubscriptionDetails
			const roles = payload
				? payload.roles
				: []
				? payload.userSubscriptionDetails
				: {}
			const allOptionalPostProcessesData = payload
				? payload.optionalPostProcesses
				: []
			const customPostProcessTemplate = payload.customPostProcessTemplate

			const optionalPostProcessesBreakDown = payload
				? payload.optionalPostProcessesBreakDown
				: []
			const optionalPostProcessAvailability = payload
				? payload.optionalPostProcessesPermissions
				: {}
			const defaultConfigurations = payload ? payload.defaultConfigurations : []
			const userCurrencySign = payload?.userCurrencySign || '$'
			const contactUsEmail = payload?.contactUsEmail || 'support@3dcastor.com'
			const defaultBomFilePath = payload?.defaultBomFilePath || ''
			const defaultMaterialsNameBom = payload?.defaultMaterialsNameBom || ''
			const defaultUserMaterialsNameBom =
				payload?.defaultUserMaterialsNameBom || ''
			const defaultGenerativeFilePath = payload?.defaultGenerativeFilePath || ''
			const defaultMetadataFilePath = payload?.defaultMetadataFilePath || ''
			const isOrganizationOwnerExist = payload?.isOrganizationOwnerExist

			const features = payload ? payload.features : [...addressFeature]

			const userOnTrial = userSubscriptionDetails.trial
			const currentMaterialTypes = []
			if (materials) {
				materials.forEach(material => {
					material.category = material.category.toLowerCase()
					if (!currentMaterialTypes.includes(material.type)) {
						currentMaterialTypes.push(material.type)
					}
				})
			}

			if (printerMaterials) {
				printerMaterials.forEach(printerMaterial => {
					if (!currentMaterialTypes.includes(printerMaterial.type)) {
						currentMaterialTypes.push(printerMaterial.type)
					}
				})
			}

			const userCustomizationSettings = payload.userCustomizationSettings
			const userProviders = payload.userProviders
			const userMaterialNamesMapping = payload.userMaterialNamesMapping
			const initialUserDefaultMaterial = [...userMaterialNamesMapping].find(
				materialName => materialName.defaultFormatType && materialName.active
			)
			const userSettingsDefaultValues =
				payload?.userCustomizationSettings.userSettingsDefaultValues
			const defaultProjectScenario =
				payload?.userCustomizationSettings.defaultProjectScenario ||
				state.defaultProjectScenario
			const co2PerKW = payload?.userCustomizationSettings.co2PerKW
			const puAnnualKgCO2 = payload?.userCustomizationSettings.puAnnualKgCO2
			const puYearsCO2 = payload?.userCustomizationSettings.puYearsCO2
			const disposeFactor = payload?.userCustomizationSettings.disposeFactor
			const holdFactor = payload?.userCustomizationSettings.holdFactor
			const carbonCreditCost =
				payload?.userCustomizationSettings.carbonCreditCost

			const defaultUploadProjectSelectedRadioButton =
				payload.defaultUploadProjectSelectedRadioButton
			const defaultProjectScenarios = payload.defaultProjectScenarios
			const defaultProjectScenarioParameters =
				payload.defaultProjectScenarioParameters
			const printingStandardsList = payload.printingStandardsList

			const partsBundle = payload?.userSubscriptionDetails.partsBundle
			const inLicense = payload?.userSubscriptionDetails.inLicense
			const editableFunctionStrings = payload?.editableFunctionStrings || []
			const quickUploadColumns = payload?.uploadProjectComponents
				.filter(component => component.fileLevel && component.show)
				.sort((a, b) => a.order - b.order)
			const isAdminByRole = roles.some(role => ADMIN_ROLES.includes(role))

			return {
				...state,
				materials,
				printerMaterials,
				printerMaterialsSubCategories,
				printerMaterialsCategories,
				materialTypes: currentMaterialTypes,
				userSettings,
				defaultSettings,
				userDetails,
				organizationDetails,
				availableOrganizations,
				allOrganizations,
				isAdminByRole,
				materialCategories,
				userOnTrial,
				filters,
				userFilters: action.payload.userFilters,
				priorities,
				usePercentExcess,
				allOptionalPostProcessesData,
				customPostProcessTemplate,
				optionalPostProcessesBreakDown,
				optionalPostProcessAvailability,
				applications,
				roles,
				threeDViewerURL,
				features,
				acceptedFileTypes,
				partsFileSizeLimit,
				notSupportedUnitTypeFormats,
				uploadProjectSelectedRadioButton,
				defaultUploadProjectSelectedRadioButton,
				defaultUploadProjectMaterialType,
				filterPartsDefaultSelection,
				userCurrencySign,
				printersFullData,
				pages,
				pagesVisited: userDetails.pagesVisited,
				userCustomizationSettings,
				printIssues,
				contactUsEmail,
				defaultConfigurations,
				userProviders,
				userMaterialNamesMapping,
				defaultProjectScenario,
				co2PerKW,
				puAnnualKgCO2,
				puYearsCO2,
				disposeFactor,
				holdFactor,
				carbonCreditCost,
				isLightUser,
				defaultProjectScenarios,
				defaultProjectScenarioParameters,
				maxAllowedUploadParts,
				maxAllowedLightUploadParts,
				allowedCountries,
				maxAllowedUploadProjects,
				uploadUnitType,
				defaultFileName,
				partsBundle,
				inLicense,
				editableFunctionStrings,
				defaultBomFilePath,
				defaultMaterialsNameBom,
				defaultUserMaterialsNameBom,
				defaultGenerativeFilePath,
				defaultMetadataFilePath,
				onboardingCompleted: userDetails.onboardingCompleted,
				initialUserDefaultMaterial,
				quickUploadColumns,
				printingStandardsList,
				isCommunicationTool: action.payload.isCommunicationTool,
				isOrganizationOwnerExist,
				userSettingsDefaultValues
			}
		}
		case PRINTER_ADDED_TO_USER: {
			const { printers, organizationId, configurationId } = action.payload
			if (organizationId !== state.organizationDetails.id && configurationId) {
				return state
			}
			return {
				...state,
				printers
			}
		}
		case PRINTER_REMOVED_FROM_USER: {
			const { printers, organizationId, configurationId } = action.payload
			if (organizationId !== state.organizationDetails.id && configurationId) {
				return state
			}
			return {
				...state,
				printers
			}
		}
		case ADMIN_PERCENT_EXCESS_TOGGLED: {
			const { percentExcess } = action.payload
			return {
				...state,
				usePercentExcess: percentExcess
			}
		}
		case MATERIAL_REMOVED_FROM_USER: {
			const { material, userMaterials, userMaterialNamesMapping } =
				action.payload

			const filteredMaterials = state.materials.filter(
				({ id: existId }) => !material.some(({ id }) => existId === id)
			)

			return {
				...state,
				materials: filteredMaterials,
				userMaterials,
				userMaterialNamesMapping: userMaterialNamesMapping
			}
		}
		case USER_PRINTER_REMOVED_FROM_USER: {
			const { printer, userPrinters } = action.payload
			const filteredPrinters = state.printers.filter(
				({ id: existId }) => !printer.some(({ id }) => existId === id)
			)
			return {
				...state,
				printers: filteredPrinters,
				userPrinters
			}
		}

		case ADMIN_MATERIAL_ADDED: {
			const material = action.payload
			const materials = sortBy([...state.materials, material], ['name'])

			return {
				...state,
				materials
			}
		}
		case ADMIN_MATERIAL_DELETED: {
			const materialId = action.payload.id
			const filteredMaterials = state.materials.filter(
				({ id }) => id !== materialId
			)

			return {
				...state,
				materials: filteredMaterials
			}
		}
		case ADMIN_PRINTER_MATERIAL_DELETED: {
			const { id } = action.payload
			const { printers, printersFullData } = action.payload?.response?.data
			const newUserPrinterMaterials = state.userPrinterMaterials.filter(
				material => material.id !== id
			)
			return {
				...state,
				printers,
				printersFullData,
				userPrinterMaterials: newUserPrinterMaterials
			}
		}
		case ADMIN_PRINTER_MATERIAL_UPDATED: {
			const { printers, printersFullData } = action.payload

			return {
				...state,
				printers,
				printersFullData
			}
		}
		case USER_PRINTER_MATERIAL_REMOVED_FROM_USER: {
			const {
				printerMaterial,
				userPrinterMaterials,
				printers,
				printersFullData
			} = action.payload

			const filteredPrinterMaterials = state.printerMaterials.filter(
				({ id: existId }) => !printerMaterial.some(({ id }) => existId === id)
			)

			return {
				...state,
				printerMaterials: filteredPrinterMaterials,
				userPrinterMaterials,
				printers,
				printersFullData
			}
		}
		case MATERIAL_ADDED_TO_USER: {
			const { material: addedMaterials, userMaterials } = action.payload
			const materials = sortBy(
				[...state.materials, ...addedMaterials],
				['name']
			)

			return {
				...state,
				materials,
				userMaterials
			}
		}
		case USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE: {
			const { userMaterials, materials } = action.payload
			const materialsSorted = sortBy(materials, ['name'])

			return {
				...state,
				materials: materialsSorted,
				userMaterials
			}
		}

		case NEW_PRINTER_ADDED_TO_USER: {
			const { userPrinters } = action.payload
			return {
				...state,
				userPrinters
			}
		}
		case NEW_PRINTER_MATERIAL_ADDED:
			const response = action.payload

			return {
				...state,
				userPrinterMaterials: [
					...state.userPrinterMaterials,
					response.printerMaterialAdded
				],
				printerMaterials: [
					...state.printerMaterials,
					response.printerMaterialAdded
				],
				printersFullData: response.printersFullData
			}
		case ADMIN_SETINGS_PRIORITIES_TOGGLED: {
			const { priorities } = action.payload
			return { ...state, priorities }
		}
		case RESET_AUTH_STATE:
			return { ...state, features: [...addressFeature] }
		case UPDATE_SHOW_TOUR: {
			return { ...state, pagesVisited: action.payload.pagesVisited }
		}
		case UPDATE_TOUR_FAILED:
			return { ...state, tourActive: false, tourError: action.error }

		case USER_CUSTOM_SETTINGS_UPDATED: {
			const { userCustomizationSettings } = action.payload
			const fullTrayAssumption =
				userCustomizationSettings.fullTrayAssumption == null
					? state.userCustomizationSettings.fullTrayAssumption
					: userCustomizationSettings.fullTrayAssumption

			return {
				...state,
				userCustomizationSettings: {
					...state.userCustomizationSettings,
					...userCustomizationSettings,
					fullTrayAssumption: !!fullTrayAssumption
				}
			}
		}

		case FULL_TRAY_ASSUMPTION_DATA_ARRANGED: {
			const { settingsData } = action.payload
			const fullTrayAssumption = isNull(
				settingsData?.userSettings?.fullTrayAssumption
			)
				? settingsData?.defaultSettings?.fullTrayAssumption
				: settingsData?.userSettings?.fullTrayAssumption

			return {
				...state,
				userCustomizationSettings: {
					...state.userCustomizationSettings,
					fullTrayAssumption: !!fullTrayAssumption
				}
			}
		}

		case USER_FILTERS_TOGGLES_UPDATED: {
			const { userFilters } = action.payload
			return {
				...state,
				userFilters
			}
		}

		case USER_FILTERS_CHANGE: {
			const { newUserFilters } = action.payload
			return {
				...state,
				userFilters: newUserFilters
			}
		}

		case UPDATE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNamesMapping, userMaterialNameMappingUpdate } =
				action.payload
			let changedUserMaterialNamesMapping = cloneDeep(
				state.userMaterialNamesMapping
			)
			const changedIndex = changedUserMaterialNamesMapping.findIndex(
				material =>
					material.expression === userMaterialNameMappingUpdate.expression
			)

			if (changedIndex > -1) {
				changedUserMaterialNamesMapping[changedIndex] =
					userMaterialNameMappingUpdate
			} else {
				changedUserMaterialNamesMapping = userMaterialNamesMapping
			}

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				),
				isEditingMaterial: false,
				initialUserDefaultMaterial: changedUserMaterialNamesMapping.find(
					materialName => materialName.defaultFormatType && materialName.active
				)
			}
		}

		case CHANGE_USER_MATERIAL_NAME_MAPPING: {
			const { userMaterialNameMapping } = action.payload

			const userMaterialNamesMappingCopy = cloneDeep(
				state.userMaterialNamesMapping
			)
			const changedUserMaterialNamesMapping = userMaterialNamesMappingCopy.map(
				userMaterialName => {
					if (userMaterialName.id === userMaterialNameMapping.id) {
						userMaterialName.material = userMaterialNameMapping.material
					}
					return userMaterialName
				}
			)

			return {
				...state,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				),
				isEditingMaterial: true
			}
		}

		case REMOVE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNamesMapping } = action.payload

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					userMaterialNamesMapping
				)
			}
		}

		case CREATE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNameMapping } = action.payload
			let changedUserMaterialNamesMapping = state.userMaterialNamesMapping
			const changedIndex = changedUserMaterialNamesMapping.findIndex(
				material => material.expression === userMaterialNameMapping.expression
			)

			if (changedIndex > -1) {
				changedUserMaterialNamesMapping[changedIndex] = userMaterialNameMapping
			} else {
				changedUserMaterialNamesMapping.push(userMaterialNameMapping)
			}

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				)
			}
		}

		case START_UPDATING_USER_MATERIAL_NAMES_MAPPING: {
			return {
				...state,
				isLoadingMaterial: action.payload.isLoading
			}
		}

		case FATCH_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNamesMapping } = action.payload
			return {
				...state,
				userMaterialNamesMapping,
				doMaterialNameMappingRefresh: false
			}
		}

		case DEFAULT_SCENARIO_CHANGED: {
			const defaultScenario = action.payload
			return {
				...state,
				defaultProjectScenario: defaultScenario
			}
		}
		case CO2_USER_SETTINGS_CARBON_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKW: value
			}
		}
		case CO2_USER_SETTINGS_DATA_LOADING: {
			return {
				...state,
				...action.payload
			}
		}
		case CO2_USER_SETTINGS_PU: {
			const { value, name } = action.payload
			return {
				...state,
				[name]: value
			}
		}
		case CO2_USER_SETTINGS_DISPOSE: {
			const { value } = action.payload
			return {
				...state,
				disposeFactor: value
			}
		}
		case CO2_USER_SETTINGS_HOLD: {
			const { value } = action.payload
			return {
				...state,
				holdFactor: value
			}
		}
		case CO2_USER_SETTINGS_CARBON_CREDIT_COST: {
			const { value } = action.payload
			return {
				...state,
				carbonCreditCost: value
			}
		}
		case UPDATE_USER_ONBOARDING_STATE: {
			const { onboardingCompleted } = action.payload
			return {
				...state,
				onboardingCompleted
			}
		}
		case POST_PROCESS_UPDATED: {
			const { optionalPostProcesses, optionalPostProcessesPermissions } =
				action.payload
			return {
				...state,
				allOptionalPostProcessesData: optionalPostProcesses,
				optionalPostProcessAvailability: optionalPostProcessesPermissions
			}
		}

		case ADMIN_PRINTER_DELETED: {
			const { id } = action.payload
			let printers = state.printers
			if (id && printers.length) {
				printers = state.printers.filter(printer => printer.id !== id)
			}
			return {
				...state,
				printers
			}
		}

		case USER_ID_UPDATE: {
			const { id } = action.payload
			return {
				...state,
				userDetails: {
					...state.userDetails,
					id
				}
			}
		}

		case COST_AND_LEAD_TBL_DATA_UPDATED: {
			const { settingsData } = action.payload
			return {
				...state,
				userCustomizationSettings: settingsData.userSettings,
				defaultSettings: settingsData.defaultSettings
			}
		}

		case USER_UNITS_TYPE_SELECTED: {
			const { unitsType } = action.payload
			return {
				...state,
				userCustomizationSettings: {
					...state.userCustomizationSettings,
					unitsType
				}
			}
		}

		case ORGANIZATION_INFO_UPDATED: {
			const { organizationDetails } = action.payload
			const newAvailableOrganizations = state.availableOrganizations.map(
				organization => {
					if (organization.id === organizationDetails.id) {
						organization.name = organizationDetails.name
						organization.private = organizationDetails.private
					}
					return organization
				}
			)
			const allOrganizations = state.allOrganizations.map(organization => {
				if (organization.id === organizationDetails.id) {
					organization.name = organizationDetails.name
				}
				return organization
			})
			return {
				...state,
				organizationDetails,
				availableOrganizations: newAvailableOrganizations,
				allOrganizations
			}
		}

		case NEW_ORGANIZATION_ADDED: {
			const { organization } = action.payload
			const newAvailableOrganizations = [
				...state.availableOrganizations,
				organization
			]
			const newAllOrganizations = [...state.allOrganizations, organization]

			return {
				...state,
				availableOrganizations: newAvailableOrganizations,
				allOrganizations: newAllOrganizations,
				onboardingCompleted: false
			}
		}

		default:
			return state
	}
}

export default userReducer
