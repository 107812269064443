import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isBoolean } from 'lodash'

import {
	ADMIN_GET_SMTP_MAIL,
	ADMIN_GET_SMTP_MAIL_ERROR,
	ADMIN_GET_SMTP_MAIL_FETCH,
	ADMIN_GET_SMTP_MAIL_LOADING,
	ADMIN_SMTP_MAIL_DATA_CHANGE,
	ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
	ADMIN_VERIFY_SMTP_MAIL,
	GET_ADMIN_SETTINGS_STOP_ENUMS,
	GET_ADMIN_SETTINGS_STOP_ENUMS_ERROR,
	GET_ADMIN_SETTINGS_STOP_LOADING,
	GET_ADMIN_SETTINGS_STOP_STEPS_LOADING,
	GET_ADMIN_SETTINGS_STOP_SUCCESS
} from './AdminSettingsTypes'
import { ActionWithPayload } from 'global actions/ActionModels'
import {
	ADMIN_PERCENT_EXCESS_TOGGLED,
	ADMIN_SETINGS_PRIORITIES_TOGGLED,
	ADMIN_SETINGS_SETUPED,
	ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { store } from 'index'
import { prepareEmailDataToSend } from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsService'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { IPriority } from 'Services/models/IPriority'
import {
	checkRecalculateProcessAdmin,
	getAdminServerStopEnums,
	getMailSettings,
	postStopServer,
	putPartNameToFail,
	putPartNameToFailAfterSaving,
	putStopServerStep,
	setPercentExcess,
	setRelativeOriginalMaterial,
	stopRecalculating,
	updateMailSettings,
	updateSingletons,
	verifyMailSettings
} from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let poller = new Poller()

export const setupAdminSettings = (
	priorities: IPriority[]
): ActionWithPayload<any> => {
	const isRelativeToOriginalMaterial = !!priorities.find(
		(priority: IPriority) => priority.relativeToOriginalMaterial
	)

	return {
		type: ADMIN_SETINGS_SETUPED,
		payload: { isRelativeToOriginalMaterial }
	}
}

export const getAdminStepEnums = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const {
				data: { stepEnum, partNameToFailSaving, partNameToFail, stopServerStep }
			} = await getAdminServerStopEnums()

			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_ENUMS,
				payload: {
					partNameToFailSaving,
					partNameToFail,
					stopServerStep,
					stepEnum
				}
			})
		} catch (err: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message || getString('SOMETHING_WENT_WRONG')
				}
			})
		}
	}
}

export const onAdminStopServer = (
	stopServerStep: string,
	partName: string,
	partNameAfterSaving: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_STEPS_LOADING,
				payload: true
			})
			await putStopServerStep(stopServerStep)
			await putPartNameToFail(partName)
			await putPartNameToFailAfterSaving(partNameAfterSaving)
			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_SUCCESS
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err: any) {
			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_ENUMS_ERROR
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message || getString('SOMETHING_WENT_WRONG')
				}
			})
		}
		dispatch({
			type: GET_ADMIN_SETTINGS_STOP_STEPS_LOADING,
			payload: false
		})
	}
}

export const onAdminStopServerInstantly = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_LOADING,
				payload: true
			})
			await postStopServer()
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: 'Monolith server stopped'
				}
			})
		} catch (err: any) {
			dispatch({
				type: GET_ADMIN_SETTINGS_STOP_ENUMS_ERROR
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('RESET_SERVER_SUCCESS')
				}
			})
		}
		dispatch({
			type: GET_ADMIN_SETTINGS_STOP_LOADING,
			payload: false
		})
	}
}

export const onPriorityToggleChange = (
	isRelativeToOriginalMaterial: boolean
): any => {
	return async (dispatch: any) => {
		try {
			const {
				data: { priorities }
			}: any = await setRelativeOriginalMaterial(isRelativeToOriginalMaterial)
			dispatch({
				type: ADMIN_SETINGS_PRIORITIES_TOGGLED,
				payload: { isRelativeToOriginalMaterial, priorities }
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || 'error switching setting'
				}
			})
		}
	}
}

export const onPercentExcessToggleChange = (percentExcess: boolean): any => {
	return async (dispatch: any) => {
		try {
			await setPercentExcess(percentExcess)
			dispatch({
				type: ADMIN_PERCENT_EXCESS_TOGGLED,
				payload: { percentExcess }
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || 'error switching setting'
				}
			})
		}
	}
}

export const stopAllRecalculatingAlert = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				headerTitle: getString('STOP_RECALCULATING'),
				confirmText: getString('STOP_RECALCULATING'),
				text: getString('ADMIN_STOP_RECALCULATING_ALERT_BODY'),
				onConfirm: () => stopAllRecalculating(dispatch),
				alertType: AlertType.WARNING
			}
		})
	}
}

export const stopAllRecalculating = async (dispatch: Dispatch<AnyAction>) => {
	dispatch({
		type: ALERT_CALCULATION_STARTED
	})
	try {
		const response = await stopRecalculating()
		if (response?.status === 200) {
			let timeOutDateTime = new Date()
			timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
			poller = new Poller(
				1000,
				timeOutDateTime,
				() => checkRecalculateProcessAdmin(),
				(res: any) => res?.data?.finished
			)

			poller
				.start()
				.then((res: any) => {
					if (res?.data?.finished) {
						dispatch({
							type: HANDLE_NOTIFICATION,
							payload: {
								notificationType: SHOW_NOTIFICATION.SUCCESS,
								notificationMessage: getString('STOP_RECALCULATING_SUCCESS_MSG')
							}
						})
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					}
				})
				.catch((error: any) => {
					console.error(error)
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
						}
					})
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
				})
		} else {
			console.error(response)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	} catch (error) {
		console.error(error)
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
			}
		})
		dispatch({
			type: ALERT_POPUP_CANCELED
		})
	}
}

export const onUpdateSingletonsClick = (): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED
			})
			await updateSingletons()
			dispatch({
				type: ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || 'error update singletons'
				}
			})
		}
	}
}

export const updateEmailManager = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: true
		})

		try {
			const { mailConfigurations, mailData } =
				store.getState().AdminSettingsReducer

			const prepareDataToSend = prepareEmailDataToSend(
				mailData,
				mailConfigurations
			)

			const response = await updateMailSettings(prepareDataToSend)

			if (response) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})
				dispatch({
					type: ADMIN_GET_SMTP_MAIL,
					payload: {
						mailConfigurations: response.data.emailSettings
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: getString('DATA_WAS_NOT_UPDATED')
					}
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_GET_SMTP_MAIL_ERROR,
				payload: true
			})
			console.error(err)
		}

		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: false
		})
	}
}

export const changeEmailManager = (name: string, value: string | number) => {
	return (dispatch: Dispatch<any>) => {
		const { mailVerified } = store.getState().AdminSettingsReducer
		if (isBoolean(mailVerified)) {
			dispatch({
				type: ADMIN_VERIFY_SMTP_MAIL,
				payload: null
			})
		}
		dispatch({
			type: ADMIN_SMTP_MAIL_DATA_CHANGE,
			payload: { name, value }
		})
	}
}

export const verifyEmailManager = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
			payload: true
		})
		try {
			const response = await verifyMailSettings()
			if (response) {
				dispatch({
					type: ADMIN_VERIFY_SMTP_MAIL,
					payload: true
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_VERIFY_SMTP_MAIL,
				payload: false
			})
		}
		dispatch({
			type: ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
			payload: false
		})
	}
}

export const getEmailManager = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: true
		})
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_FETCH,
			payload: true
		})
		try {
			const response = await getMailSettings()
			if (response) {
				dispatch({
					type: ADMIN_GET_SMTP_MAIL,
					payload: {
						mailConfigurations: response.data.emailSettings
					}
				})
				dispatch({
					type: ADMIN_GET_SMTP_MAIL_LOADING,
					payload: false
				})
			} else {
				dispatch({
					type: ADMIN_GET_SMTP_MAIL_ERROR,
					payload: true
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_GET_SMTP_MAIL_ERROR,
				payload: true
			})

			console.error(err)
		}
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: false
		})

		dispatch({
			type: ADMIN_GET_SMTP_MAIL_FETCH,
			payload: false
		})
	}
}
