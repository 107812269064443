export const EMAIL_CHANGED = 'email_changed'
export const LOGIN_PASSWORD_CHANGED = 'LOGIN_PASSWORD_CHANGED'
export const RESISTER_PASSWORD_CHANGED = 'RESISTER_PASSWORD_CHANGED'
export const REENTER_PASSWORD_CHANGED = 'reenter_password_changed'
export const UPLOAD_LICENSE_SCRIPT = 'UPLOAD_LICENSE_SCRIPT'
export const UPLOAD_LICENSE_SCRIPT_SUCCESS = 'UPLOAD_LICENSE_SCRIPT_SUCCESS'

export const FORGOT_PASSWORD_CHANGED = 'forgot_password_changed'
export const FORGOT_REENTER_PASSWORD_CHANGED = 'forgot_reenter_password_changed'
export const FETCH_QR = 'FETCH_QR'
export const TWO_FACTOR_LOGIN_SUCCESSED = 'TWO_FACTOR_LOGIN_SUCCESSED'
export const TWO_FACTOR_CODE_CHANGED = 'TWO_FACTOR_CODE_CHANGED'
export const USER_TWO_FACTOR_AUTH_CONFIRM = 'USER_TWO_FACTOR_AUTH_CONFIRM'
export const USER_SAVED_QR = 'USER_SAVED_QR'
export const TWO_FACTOR_AUTH_SETUP_FINISH = 'TWO_FACTOR_AUTH_SETUP_FINISH'
export const TOGGLE_ADMIN_LOGIN_HELP_POPUP = 'TOGGLE_ADMIN_LOGIN_HELP_POPUP'
export const RESET_TOW_FACTOR_STATE = 'RESET_TOW_FACTOR_STATE'
export const TOW_FACTOR_WRONG_CODE = 'TOW_FACTOR_WRONG_CODE'
export const SETUP_ADMIN_HOME = 'SETUP_ADMIN_HOME'
export const USER_ADDRESS_CHANGED = 'USER_ADDRESS_CHANGED'
export const NAME_CHANGED = 'name_changed'
export const COMPANY_CHANGED = 'company_changed'
export const NEW_PAGE_VISITED = 'NEW_PAGE_VISITED'
export const CHECKBOX_CLICKED = 'CHECKBOX_CLICKED'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const SKIP_LOGIN_PROVIDER = 'SKIP_LOGIN_PROVIDER'
export const SEND_RESET_REQUEST_FAIL = 'SEND_RESET_REQUEST_FAIL'
export const SEND_RESET_REQUEST_CONFIRMED = 'SEND_RESET_REQUEST_CONFIRMED'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const LOGIN_USER = 'LOGIN_USER'
export const GOT_LOGIN_DATA = 'GOT_LOGIN_DATA'
export const PRINTER_ADDED_TO_USER = 'PRINTER_ADDED_TO_USER'
export const PRINTER_REMOVED_FROM_USER = 'PRINTER_REMOVED_FROM_USER'
export const ADMIN_PERCENT_EXCESS_TOGGLED = 'ADMIN_PERCENT_EXCESS_TOGGLED'
export const GOT_MATERIAL_TECHNOLOGIES_DATA = 'GOT_MATERIAL_TECHNOLOGIES_DATA'
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE'
export const REGISTER_USER_SUCCESS = 'register_user_success'
export const REGISTER_USER_FAIL = 'register_user_fail'
export const REGISTER_USER = 'register_user'
export const SHOW_PASSWORD_STRENGTH_ALERT = 'SHOW_PASSWORD_STRENGTH_ALERT'
export const SHOW_FORGOT_PASSWORD_STRENGTH_ALERT =
	'SHOW_FORGOT_PASSWORD_STRENGTH_ALERT'
export const USER_DETAIL_CHANGED = 'USER_DETAIL_CHANGED'
export const USER_LANGUAGE_CHANGED = 'USER_LANGUAGE_CHANGED'
export const FORGOT_PASSWORD_POPUP_OPEND = 'FORGOT_PASSWORD_POPUP_OPEND'
export const SHOW_EMAIL_SENDING_SUCCESS_POPUP =
	'SHOW_EMAIL_SENDING_SUCCESS_POPUP'
export const SHOW_RESET_PASSWORD_SUCCESS_POP_UP =
	'SHOW_RESET_PASSWORD_SUCCESS_POP_UP'
export const CONFIRM_RESTART_PASSWORD_EMAIL_FAILED =
	'CONFIRM_RESTART_PASSWORD_EMAIL_FAILED'
export const CONNECT_CASTOR_CLICKED = 'CONNECT_CASTOR_CLICKED'
export const CONNECT_CASTOR_POPUP_CANCEL_CLICKED =
	'CONNECT_CASTOR_POPUP_CANCEL_CLICKED'
export const SUBSCRIPTION_POPUP_CANCEL_CLICKED =
	'SUBSCRIPTION_POPUP_CANCEL_CLICKED'
export const CONNECT_CASTOR_RADIO_BUTTON_VALUE =
	'CONNECT_CASTOR_RADIO_BUTTON_VALUE'
export const USER_CHOICE_SUBSCRIPTION_SENDED = 'USER_CHOICE_SUBSCRIPTION_SENDED'
export const USER_SUBSCRIPTION_REQUEST_LOADING =
	'USER_SUBSCRIPTION_REQUEST_LOADING'
export const USER_SUBSCRIPTION_REQUEST_SUCCEED =
	'USER_SUBSCRIPTION_REQUEST_SUCCEED'
export const EXPLODE_PROJECT_SUCCESS = 'EXPLODE_PROJECT_SUCCESS'
export const BEGIN_UPLOAD = 'begin_upload'
export const CREATE_PROJECT_FAILED = 'create_project_failed'
export const UNIT_TYPE_CREATE_PROJECT_FAILED = 'UNIT_TYPE_CREATE_PROJECT_FAILED'
export const UNIT_TYPE_CREATE_PROJECT_RESET = 'UNIT_TYPE_CREATE_PROJECT_RESET'
export const CREATE_PROJECT_SUCCESS = 'create_project_success'
export const CREATE_PROJECT = 'create_project'
export const UPLOAD_PROJECT_TOLERANCE_TOGGLED =
	'UPLOAD_PROJECT_TOLERANCE_TOGGLED'
export const UPLOAD_PROJECT_TOLERANCE_CHANGED =
	'UPLOAD_PROJECT_TOLERANCE_CHANGED'
export const UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE =
	'UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE'
export const UPLOAD_PROJECT_MANUFACTURING_CHANGE =
	'UPLOAD_PROJECT_MANUFACTURING_CHANGE'
export const UPLOAD_PROJECT_AM_VALUE_CHANGE = 'UPLOAD_PROJECT_AM_VALUE_CHANGE'
export const FILE_MATERIAL_OR_QUANTITY_CHANGED =
	'FILE_MATERIAL_OR_QUANTITY_CHANGED'
export const ADDITIONAL_NOTES_CHANGED = 'ADDITIONAL_NOTES_CHANGED'
export const QUICK_PROJECT_PUBLISHED = 'QUICK_PROJECT_PUBLISHED'
export const QUICK_PROJECT_FAILED = 'QUICK_PROJECT_FAILED'
export const POLLER_CHECK = 'POLLER_CHECK'
export const QUICK_PROJECT_CONNECTION_ERROR_FAILED =
	'QUICK_PROJECT_CONNECTION_ERROR_FAILED'
export const QUICK_PROJECT_POLLER_STARTED = 'QUICK_PROJECT_POLLER_STARTED'

export const UPLOAD_PROJECT_SETUPED = 'UPLOAD_PROJECT_SETUPED'
export const UPLOAD_PROJECT_SCENARIO_CHANGE = 'UPLOAD_PROJECT_SCENARIO_CHANGE'
export const UPLOAD_START_LOADING = 'UPLOAD_START_LOADING'
export const UPLOAD_PROJECT_STANDART_COST_CHECKBOX =
	'UPLOAD_PROJECT_STANDART_COST_CHECKBOX'
export const UPLOAD_PROJECT_SHOW_TOUR_UPDATED =
	'UPLOAD_PROJECT_SHOW_TOUR_UPDATED'
export const ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP =
	'ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP'

export const ADD_MULTIPLE_USER_MATERIALS_BOM_FILE =
	'ADD_MULTIPLE_USER_MATERIALS_BOM_FILE'
export const ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING =
	'ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING'
export const UPLOAD_PROJECT_FILTER_TOGGLED = 'UPLOAD_PROJECT_FILTER_TOGGLED'
export const UPLOAD_PROJECT_FILTER_RESET = 'UPLOAD_PROJECT_FILTER_RESET'
export const UPLOAD_PROJECT_FILTER_REMOVED = 'UPLOAD_PROJECT_FILTER_REMOVED'
export const UPLOAD_PROJECT_FILTER_ALERT_CLOSED =
	'UPLOAD_PROJECT_FILTER_ALERT_CLOSED'
export const UPLOAD_PROJECT_FILTER_ALERT_CONFIRMED =
	'UPLOAD_PROJECT_FILTER_ALERT_CONFIRMED'
export const UPLOAD_PROJECT_FILTER_FEATURES_ALERT_CONFIRMED =
	'UPLOAD_PROJECT_FILTER_FEATURES_ALERT_CONFIRMED'
export const UPLOAD_COMPLETE = 'upload_complete'
export const UPLOAD_FILE_COMPLETE = 'UPLOAD_FILE_COMPLETE'
export const UPLOAD_FAILED_FILES = 'UPLOAD_FAILED_FILES'
export const GET_USER_REMAINING_PARTS = 'GET_USER_REMAINING_PARTS'

export const UPLOAD_FAILED = 'upload_failed'
export const ADDED_FILES = 'added_files'
export const REMOVED_FILES = 'REMOVED_FILES'
export const PROJECT_APPLICATION_CHANGED = 'PROJECT_APPLICATION_CHANGED'
export const PROJECT_NAME_CHANGED = 'project_name_changed'
export const DRAGGING_OVER_DROPZONE = 'DRAGGING_OVER_DROPZONE',
	ERP_NAME_CHANGED = 'ERP_name_changed',
	CAD_NAME_CHANGED = 'CAD_name_changed',
	QUANTITY_CHANGED = 'QUANTITY_CHANGED',
	CLEAN_STATE = 'CLEAN_STATE',
	UPLOAD_TYPE_CHANGED = 'UPLOAD_TYPE_CHANGED',
	UPLOAD_UNIT_TYPE_CHANGED = 'UPLOAD_UNIT_TYPE_CHANGED',
	UPLOAD_CHECK_STATUS = 'UPLOAD_CHECK_STATUS',
	UPLOAD_CLEAR_STATUS = 'UPLOAD_CLEAR_STATUS',
	MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ =
		'MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ',
	MATERIAL_VALUE_CHANGED_UPLOAD_PROJ = 'MATERIAL_VALUE_CHANGED_UPLOAD_PROJ',
	BOM_SELECTED_UPLOAD_PROJ = 'BOM_SELECTED_UPLOAD_PROJ',
	BOM_REMOVED_UPLOAD_PROJ = 'BOM_REMOVED_UPLOAD_PROJ',
	REMOVE_FILE_FROM_UPLOAD_DROPZONE = 'REMOVE_FILE_FROM_UPLOAD_DROPZONE',
	MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR =
		'MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR',
	PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR =
		'PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR',
	MATERIAL_CHANGED_IN_MATERIAL_SELECTOR =
		'MATERIAL_CHANGED_IN_MATERIAL_SELECTOR',
	PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR =
		'PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR',
	SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR =
		'SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR',
	MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR =
		'MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR',
	PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR =
		'PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR',
	HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP = 'HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP',
	UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP =
		'UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP'
export const SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED =
	'SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED'
export const SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED =
	'SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED'
export const SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED =
	'SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED'
export const SIMPLE_CONFIGURATION_INHOUSE_TOGGLED =
	'SIMPLE_CONFIGURATION_INHOUSE_TOGGLED'
export const LIFE_CYCLE_CHANGED = 'LIFE_CYCLE_CHANGED'
export const LIFE_CYCLE_FORM_SUBMITED = 'LIFE_CYCLE_FORM_SUBMITED'
export const FEA_NEW_ANALYSIS_CLICKED = 'FEA_NEW_ANALYSIS_CLICKED'
export const FEA_NEW_ANALYSIS_TOGGLED = 'FEA_NEW_ANALYSIS_TOGGLED'
export const FEA_OLD_ANALYSIS_TOGGLED = 'FEA_OLD_ANALYSIS_TOGGLED'
export const FEA_ANALYSIS_ALERT_CANCELED = 'FEA_NEW_ANALYSIS_ALERT_CANCELED'
export const FEA_ANALYSIS_ALERT_LOADER_TOGGLED =
	'FEA_ANALYSIS_ALERT_LOADER_TOGGLED'
export const PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED =
	'PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED'
export const PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED =
	'PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED'
export const PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED =
	'PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED'

export const FEA_ANALYSIS_DATA_FETCHED = 'FEA_NEW_ANALYSIS_DATA_FETCHED'
export const FEA_ANALYSIS_DATA_RESET = 'FEA_ANALYSIS_DATA_RESET'
export const PART_FEA_ANALYSIS_DATA_FETCHED = 'PART_FEA_ANALYSIS_DATA_FETCHED'

export const SETUP_PRINTERS_PICKER = 'SETUP_PRINTERS_PICKER'
export const PRINTER_CHANGED_IN_PRINTERS_SELECTOR =
	'PRINTER_CHANGED_IN_PRINTERS_SELECTOR'

export const MATERIAL_SELECTOR_TOGGLED = 'MATERIAL_SELECTOR_TOGGLED'
export const AM_MATERIAL_SELECTOR_TOGGLED = 'AM_MATERIAL_SELECTOR_TOGGLED'
export const SETUP_MATERIAL_PICKER = 'SETUP_MATERIAL_PICKER'
export const SETUP_PRINTER_MATERIAL_PICKER = 'SETUP_PRINTER_MATERIAL_PICKER'
export const MATERIAL_INITIAL_SET_UP = 'MATERIAL_INITIAL_SET_UP'

export const LOGIN_STATE_CHANGED = 'LOGIN_STATE_CHANGED'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const FAILED_TO_GET_STATE = 'LOGIN_STATE_GET_FAILED'

export const STAR_PART_SUCCESS = 'STAR_PART_SUCCESS'

export const PROJECT_ID_CHANGED = 'PROJECT_ID_CHANGED'
export const PART_NAME_CHANGED = 'PART_NAME_CHANGED'
export const RESULT_TYPE_CHANGED = 'RESULT_TYPE_CHANGED'
export const PRINTER_TYPE_CHANGED = 'PRINTER_TYPE_CHANGED'
export const MATERIAL_TYPE_CHANGED = 'MATERIAL_TYPE_CHANGED'
export const AM_MATERIAL_TYPE_CHANGED = 'AM_MATERIAL_TYPE_CHANGED'
export const MATERIAL_CATEGORY_CHANGED = 'MATERIAL_CATEGORY_CHANGED'
export const AM_MATERIAL_CATEGORY_CHANGED = 'AM_MATERIAL_CATEGORY_CHANGED'
export const MATERIAL_CHANGED = 'MATERIAL_CHANGED'
export const AM_MATERIAL_CHANGED = 'AM_MATERIAL_CHANGED'
export const AM_SUB_CATEGORY_CHANGED = 'AM_SUB_CATEGORY_CHANGED'

export const SUBMIT_FORM_CLICKED = 'SUBMIT_FORM_CLICKED'
export const ORIGINAL_PRICE_CHANGED = 'ORIGINAL_PRICE_CHANGED'
export const MOLD_PRICE_CHANGED = 'MOLD_PRICE_CHANGED'
export const MOLD_MAINTENANCE_PRICE_CHANGED = 'MOLD_MAINTENANCE_PRICE_CHANGED'
export const MINIMUM_ORDER_CHANGED = 'MINIMUM_ORDER_CHANGED'
export const DFM_COSTS_CHANGED = 'DFM_COSTS_CHANGED'
export const PART_COUNT_CHANGED = 'PART_COUNT_CHANGED'
export const PART_COUNT_SUBMITED = 'PART_COUNT_SUBMITED'
export const PART_FINANCIAL_UPDATED = 'PART_FINANCIAL_UPDATED'
export const PART_ANALYSIS_WALL_THICKNESS_UPDATED =
	'PART_ANALYSIS_WALL_THICKNESS_UPDATED'
export const ERROR_CHANGED = 'ERROR_CHANGED'
export const PART_FAILURE_REASON = 'PART_FAILURE_REASON'
export const FIX_PART_SUGGESTIONS = 'FIX_PART_SUGGESTIONS'
export const ADDED_PART_ANALYSIS_SUCCESS = 'ADDED_PART_ANALYSIS_SUCCESS'
export const ADDED_PART_ANALYSIS_FAIL = 'ADDED_PART_ANALYSIS_FAIL'
export const REMOVE_TOAST_MESSAGE = 'REMOVE_TOAST_MESSAGE'

//global
export const HANDLE_LOADER = 'HANDLE_LOADER'
export const RESET_LOADER = 'RESET_LOADER'

export const SETUP_SSE_CONNECTIONS = 'SETUP_SSE_CONNECTIONS'
export const CLOSE_SSE_CONNECTIONS = 'CLOSE_SSE_CONNECTIONS'
export const RESET_SSE_CONNECTIONS = 'RESET_SSE_CONNECTIONS'
//filter
export const FILTER_PARTS_STAR = 'FILTER_PARTS_STAR'
export const FILTER_PARTS_PRINTABLE = 'FILTER_PARTS_PRINTABLE'
export const FILTER_PARTS_ALL = 'FILTER_PARTS_ALL'
export const FILTER_PARTS_BY_KEY = 'FILTER_PARTS_BY_KEY'
export const CHANGE_FILTERS_KEY = 'CHANGE_FILTERS_KEY'
export const FILTER_PARTS_BENEFITS = 'FILTER_PARTS_BENEFITS'
export const FILTER_PARTS_ALL_WITH_FAILED = 'FILTER_PARTS_ALL_WITH_FAILED'
export const CHANGE_SEARCH_PHRASE = 'CHANGE_SEARCH_PHRASE'
export const CHANGE_FILTER_PHRASE = 'CHANGE_FILTER_PHRASE'

//user home
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED'
export const USER_HOME_DRAW_TOGGLE = 'USER_HOME_DRAW_TOGGLE'
export const USER_HOME_PROJECT_REMOVED = 'USER_HOME_PROJECT_REMOVED'
export const USER_HOME_ROUTE_CHANGED = 'USER_HOME_ROUTE_CHANGED'
export const USER_HOME_CRUMB_NAME_CHANGED = 'USER_HOME_CRUMB_NAME_CHANGED'
export const USER_HOME_CRUMB_RESET = 'USER_HOME_CRUMB_RESET'
export const USER_HOME_CRUMB_NAMES_CHANGED = 'USER_HOME_CRUMB_NAMES_CHANGED'

//Admin home
export const ADMIN_HOME_DRAW_TOGGLE = 'ADMIN_HOME_DRAW_TOGGLE'
//Admin projects
export const GET_ADMIN_PROJECTS_SUCCESS = 'GET_ADMIN_PROJECTS_SUCCESS'
export const SERVER_CALL_FAILED_ADMIN = 'SERVER_CALL_FAILED_ADMIN'
export const GET_ADMIN_PROJECTS = 'GET_ADMIN_PROJECTS'
export const GET_ADMIN_PROJECTS_UNMOUNTED = 'GET_ADMIN_PROJECTS_UNMOUNTED'
export const ADMIN_PROJECT_FETCHED = 'ADMIN_PROJECT_FETCHED'
export const PUBLISH_PROJECT_ADMIN_PAGE = 'PUBLISH_PROJECT_ADMIN_PAGE'
export const PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED =
	'PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED'
export const ALERT_CANCEL_CLICKED_ADMIN_PROJECTS =
	'ALERT_CANCEL_CLICKED_ADMIN_PROJECTS'
export const PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS =
	'PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS'
export const VIEW_PROJECT_CLICKED = 'VIEW_PROJECT_CLICKED'
export const VIEW_PART_LIST_CLICKED = 'VIEW_PART_LIST_CLICKED'
export const ADMIN_PROJECT_SELECTED = 'ADMIN_PROJECT_SELECTED'
export const ADMIN_PROJECT_DOWNLOAD_CLICKED = 'ADMIN_PROJECT_DOWNLOAD_CLICKED'
export const ADMIN_PROJECT_UPDATE_TOGGLED = 'ADMIN_PROJECT_UPDATE_TOGGLED'
export const ADMIN_PROJECT_UPDATED = 'ADMIN_PROJECT_UPDATED'
export const ADMIN_PROJECT_SEARCH_PHRASE_CHANGED =
	'ADMIN_PROJECT_SEARCH_PHRASE_CHANGED'
export const ADMIN_PROJECT_FILTER_TYPE_CHANGED =
	'ADMIN_PROJECT_FILTER_TYPE_CHANGED'
export const ADMIN_PROJECT_DATEPICKER_TOGGLED =
	'ADMIN_PROJECT_DATEPICKER_TOGGLED'
export const ADMIN_PROJECT_GENERATE_REPORT_LOADING =
	'ADMIN_PROJECT_GENERATE_REPORT_LOADING'
export const ADMIN_USER_DATEPICKER_TOGGLED = 'ADMIN_USER_DATEPICKER_TOGGLED'
export const ADMIN_USER_GENERATE_REPORT_LOADING =
	'ADMIN_USER_GENERATE_REPORT_LOADING'
//admin parts
export const PART_ADMIN_SERVER_CALL_FAILED = 'PART_ADMIN_SERVER_CALL_FAILED'
export const PART_ADMIN_DELETE_PARTS = 'PART_ADMIN_DELETE_PARTS'
export const PART_ADMIN_DELETE_PARTS_SUCCESS = 'PART_ADMIN_DELETE_PARTS_SUCCESS'
export const PART_ADMIN_MERGE_PARTS = 'PART_ADMIN_MERGE_PARTS'
export const PART_ADMIN_MERGE_PARTS_SUCCESS = 'PART_ADMIN_MERGE_PARTS_SUCCESS'
export const PART_ADMIN_MORE_PARTS_FETCHED = 'PART_ADMIN_MORE_PARTS_FETCHED'
export const PART_ADMIN_MORE_CLUSTERS_FETCHED =
	'PART_ADMIN_MORE_CLUSTERS_FETCHED'
export const PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED =
	'PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED'
export const PART_ADMIN_SELECTED_ACTION = 'PART_ADMIN_SELECTED_ACTION'
export const PART_ADMIN_GET_PARTS = 'PART_ADMIN_GET_PARTS'
export const PART_ADMIN_GET_PARTS_SUCCESS = 'PART_ADMIN_GET_PARTS_SUCCESS'
export const PART_ADMIN_CHECKBOX_SELECTED = 'PART_ADMIN_CHECKBOX_SELECTED'
export const PART_ADMIN_DOWNLOAD_STL = 'PART_ADMIN_DOWNLOAD_STL'
export const PART_ADMIN_DOWNLOAD_STL_COMPLETE =
	'PART_ADMIN_DOWNLOAD_STL_COMPLETE'
export const PART_ADMIN_DOWNLOAD_STL_SUCCESS = 'PART_ADMIN_DOWNLOAD_STL_SUCCESS'
export const PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED =
	'PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED'
export const PART_ADMIN_PUBLISH_CLUSTER_TOGGLED =
	'PART_ADMIN_PUBLISH_CLUSTER_TOGGLED'
export const PART_ADMIN_ALL_CLUSTER_PUBLISHED =
	'PART_ADMIN_ALL_CLUSTER_PUBLISHED'
export const PART_ADMIN_CLUSTER_PUBLISHED = 'PART_ADMIN_CLUSTER_PUBLISHED'
export const PART_ADMIN_CLUSTER_REJECTED = 'PART_ADMIN_CLUSTER_REJECTED'
export const PART_ADMIN_CLUSTER_REMOVED = 'PART_ADMIN_CLUSTER_REMOVED'
export const PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED =
	'PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED'
export const PART_ADMIN_REJECT_CLUSTER_TOGGLED =
	'PART_ADMIN_REJECT_CLUSTER_TOGGLED'
export const PART_ADMIN_REMOVE_CLUSTER_TOGGLED =
	'PART_ADMIN_REMOVE_CLUSTER_TOGGLED'
export const PART_ADMIN_UPDATE_CLUSTER_TOGGLED =
	'PART_ADMIN_UPDATE_CLUSTER_TOGGLED'

//admin users
export const GET_ADMIN_USERS_CALLED = 'GET_ADMIN_USERS_CALLED'
export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS'
export const GET_ADMIN_USERS_GOT_ERROR = 'GET_ADMIN_USERS_GOT_ERROR'
export const GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED =
	'GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED'
export const GET_ADMIN_SORT_BY_CREATED_CLICKED =
	'GET_ADMIN_SORT_BY_CREATED_CLICKED'
export const ADMIN_USERS_DESKTOP_PRINTER_TOGGLED =
	'ADMIN_USERS_DESKTOP_PRINTER_TOGGLED'
export const ADMIN_USERS_SEARCH_PHRASE_CHANGED =
	'ADMIN_USERS_SEARCH_PHRASE_CHANGED'
export const ADMIN_USER_DELETED = 'ADMIN_USER_DELETED'
export const ADMIN_USER_SELECTED = 'ADMIN_USER_SELECTED'
export const ADMIN_USER_INFO_UPDATE_TOGGLED = 'ADMIN_USER_INFO_UPDATE_TOGGLED'
export const ADMIN_USER_PERMISSION_TOGGLED = 'ADMIN_USER_PERMISSION_TOGGLED'
export const ADMIN_USER_PERMISSION_SUCCESS = 'ADMIN_USER_PERMISSION_SUCCESS'
export const ADMIN_USER_INFO_UPDATE_SUCCESS = 'ADMIN_USER_INFO_UPDATE_SUCCESS'
// export const GET_ADMIN_USER = 'GET_ADMIN_USER'
export const ADMIN_USER_FETCHED = 'ADMIN_USER_FETCHED'
export const GET_ADMIN_USER_INFO = 'GET_ADMIN_USER_INFO'
export const GET_ADMIN_USER_INFO_ERROR = 'GET_ADMIN_USER_INFO_ERROR'

//admin printers
export const GET_ADMIN_PRINTERS = 'GET_ADMIN_PRINTERS'
export const GET_ADMIN_PRINTERS_SUCCESS = 'GET_ADMIN_PRINTERS_SUCCESS'
export const GET_ADMIN_PRINTERS_GOT_ERROR = 'GET_ADMIN_PRINTERS_GOT_ERROR'
export const GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED =
	'GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED'
export const ADMIN_PRINTER_UPDATED = 'ADMIN_PRINTER_UPDATED'
export const ADMIN_PRINTER_RESET_DATA = 'ADMIN_PRINTER_RESET_DATA'
export const ADMIN_PRINTER_UPDATE_GOT_ERROR = 'ADMIN_PRINTER_UPDATE_GOT_ERROR'
export const ADMIN_PRINTER_DELETED = 'ADMIN_PRINTER_DELETED'
export const ADMIN_PRINTER_DELETED_GOT_ERROR = 'ADMIN_PRINTER_DELETED_GOT_ERROR'
export const ADMIN_PRINTER_NEW_COMPANY_SELECTED =
	'ADMIN_PRINTER_NEW_COMPANY_SELECTED'
export const ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED =
	'ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED'
export const ADMIN_PRINTER_ADDED = 'ADMIN_PRINTER_ADDED'

export const USER_ID_UPDATE = 'USER_ID_UPDATE'
export const USER_PRINTERS_ADDED_SUCCESS = 'USER_PRINTERS_ADDED_SUCCESS'
export const USER_PRINTERS_NAME_ALREADY_EXIST_ERROR =
	'USER_PRINTERS_NAME_ALREADY_EXIST_ERROR'
export const USER_PRINTERS_UPDATE_ERROR = 'USER_PRINTERS_UPDATE_ERROR'

export const USER_PRINTER_ADDED = 'USER_PRINTER_ADDED'

export const USER_PRINTER_INITIAL_VALUES_HAD_SET =
	'USER_PRINTER_INITIAL_VALUES_HAD_SET'

export const ADMIN_PRINTER_SEARCH_PHRASE_CHANGED =
	'ADMIN_PRINTER_SEARCH_PHRASE_CHANGED'
export const ADMIN_PRINTER_INITIAL_VALUES_HAD_SET =
	'ADMIN_PRINTER_INITIAL_VALUES_HAD_SET'
export const USER_INFO_EDIT_SELECT = 'USER_INFO_EDIT_SELECT'
//admin materials
export const GET_ADMIN_MATERIALS = 'GET_ADMIN_MATERIALS'
export const GET_ADMIN_MATERIALS_SUCCESS = 'GET_ADMIN_MATERIALS_SUCCESS'
export const GET_ADMIN_MATERIALS_GOT_ERROR = 'GET_ADMIN_MATERIALS_GOT_ERROR'
export const GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED =
	'GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED'
export const ADMIN_MATERIAL_UPDATED = 'ADMIN_MATERIAL_UPDATED'
export const ADMIN_MATERIAL_UPDATE_GOT_ERROR = 'ADMIN_MATERIAL_UPDATE_GOT_ERROR'
export const ADMIN_MATERIAL_DELETED = 'ADMIN_MATERIAL_DELETED'
export const ADMIN_MATERIAL_DELETED_GOT_ERROR =
	'ADMIN_MATERIAL_DELETED_GOT_ERROR'
export const ADMIN_MATERIAL_ADDED = 'ADMIN_MATERIAL_ADDED'
export const ADMIN_MATERIAL_TYPE_CHANGED = 'ADMIN_MATERIAL_TYPE_CHANGED'
export const ADMIN_MATERIAL_NEW_FORM_OPENED = 'ADMIN_MATERIAL_NEW_FORM_OPENED'
export const ADMIN_MATERIAL_EDIT_FORM_OPENED = 'ADMIN_MATERIAL_EDIT_FORM_OPENED'
export const ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED =
	'ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED'

//admin printer materials
export const GET_ADMIN_PRINTER_MATERIALS = 'GET_ADMIN_PRINTER_MATERIALS'
export const GET_ADMIN_PRINTER_MATERIALS_SUCCESS =
	'GET_ADMIN_PRINTER_MATERIALS_SUCCESS'
export const GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR =
	'GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR'
export const GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED =
	'GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED'
export const ADMIN_PRINTER_MATERIAL_UPDATED = 'ADMIN_PRINTER_MATERIAL_UPDATED'
export const ADMIN_PRINTER_MATERIAL_UPDATE_GOT_ERROR =
	'ADMIN_PRINTER_MATERIAL_UPDATE_GOT_ERROR'
export const ADMIN_PRINTER_MATERIAL_DELETED = 'ADMIN_PRINTER_MATERIAL_DELETED'
export const ADMIN_PRINTER_MATERIAL_DELETED_GOT_ERROR =
	'ADMIN_PRINTER_MATERIAL_DELETED_GOT_ERROR'
export const NEW_PRINTER_MATERIAL_ADDED = 'NEW_PRINTER_MATERIAL_ADDED'
export const ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED =
	'ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED'

//admin printer names
export const GET_ADMIN_PRINTER_NAMES = 'GET_ADMIN_PRINTER_NAMES'
export const GET_ADMIN_PRINTER_NAMES_SUCCESS =
		'GET_ADMIN_PRINTER_NAMES_SUCCESS',
	GET_ADMIN_PRINTER_NAMES_GOT_ERROR = 'GET_ADMIN_PRINTER_NAMES_GOT_ERROR',
	GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED =
		'GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED',
	ADMIN_PRINTER_NAME_UPDATED = 'ADMIN_PRINTER_NAME_UPDATED',
	ADMIN_PRINTER_NAME_UPDATE_GOT_ERROR = 'ADMIN_PRINTER_NAME_UPDATE_GOT_ERROR',
	ADMIN_PRINTER_NAME_DELETED = 'ADMIN_PRINTER_NAME_DELETED',
	ADMIN_PRINTER_NAME_DELETED_GOT_ERROR = 'ADMIN_PRINTER_NAME_DELETED_GOT_ERROR',
	ADMIN_PRINTER_NAME_ADDED = 'ADMIN_PRINTER_NAME_ADDED',
	ADMIN_PRINTER_NAMES_SELECTED_CLEARED = 'ADMIN_PRINTER_NAMES_SELECTED_CLEARED',
	ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED =
		'ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED'

//admin materialPrinterPairs
export const GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS =
	'GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS'
export const GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR =
	'GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR'
export const GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED =
	'GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED'
export const ADMIN_MATERIAL_PRINTER_PAIR_UPDATED =
	'ADMIN_MATERIAL_PRINTER_PAIR_UPDATED'
export const ADMIN_MATERIAL_PRINTER_PAIR_UPDATE_GOT_ERROR =
	'ADMIN_MATERIAL_PRINTER_PAIR_UPDATE_GOT_ERROR'
export const ADMIN_MATERIAL_PRINTER_PAIR_DELETED =
	'ADMIN_MATERIAL_PRINTER_PAIR_DELETED'
export const ADMIN_MATERIAL_PRINTER_PAIR_DELETED_GOT_ERROR =
	'ADMIN_MATERIAL_PRINTER_PAIR_DELETED_GOT_ERROR'
export const ADMIN_MATERIAL_PRINTER_PAIR_ADDED =
	'ADMIN_MATERIAL_PRINTER_PAIR_ADDED'
export const ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED =
	'ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED'
export const ADMIN_MATERIAL_PRINTER_PAIR_INITIAL_VALUES_HAD_SET =
	'ADMIN_MATERIAL_PRINTER_PAIR_INITIAL_VALUES_HAD_SET'

export const NEW_ITEM_ADD_CALLED = 'NEW_ITEM_ADD_CALLED'
export const NEW_ITEM_ADD_FAILED = 'NEW_ITEM_ADD_FAILED'

// admin settings
export const ADMIN_SETINGS_PRIORITIES_TOGGLED =
	'ADMIN_SETINGS_PRIORITIES_TOGGLED'
export const ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED =
	'ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED'
export const ADMIN_SETINGS_SETUPED = 'ADMIN_SETINGS_SETUPED'

// admin settings
export const ADMIN_STRINGS_IMPORT_TOGGLED = 'ADMIN_STRINGS_IMPORT_TOGGLED'

//project analysis
export const CHANGE_INITIAL_PROJECT_SETUP = 'CHANGE_INITIAL_PROJECT_SETUP'
export const DO_REFRESH_CONFIGURATIONS = 'DO_REFRESH_CONFIGURATIONS'
export const GET_PROJECT_ANALYSIS = 'GET_PROJECT_ANALYSIS'
export const GET_PROJECT_INFO_SUCCESS = 'GET_PROJECT_INFO_SUCCESS'
export const GET_PROJECT_INFO = 'GET_PROJECT_INFO'
export const GET_PROJECT_INFO_FAIL = 'GET_PROJECT_INFO_FAIL'
export const GET_PROJECT_ANALYSIS_SUCCESS = 'GET_PROJECT_ANALYSIS_SUCCESS'
export const GET_WEIGHT_REDUCTION_SUCCESS = 'GET_WEIGHT_REDUCTION_SUCCESS'
export const GET_WEIGHT_REDUCTION_BUNDLE_SUCCESS =
	'GET_WEIGHT_REDUCTION_BUNDLE_SUCCESS'
export const GET_PROJECT_PARTS_SUCCESS = 'GET_PROJECT_PARTS_SUCCESS'
export const GET_PROJECT_PARTS_TO_PRINT_SUCCESS =
	'GET_PROJECT_PARTS_TO_PRINT_SUCCESS'
export const GET_PROJECT_PARTS_TO_PRINT_FROM_STATE =
	'GET_PROJECT_PARTS_TO_PRINT_FROM_STATE'
export const GET_PROJECT_PARTS_STARTED = 'GET_PROJECT_PARTS_STARTED'
export const GET_PROJECT_PARTS_TO_PRINT_STARTED =
	'GET_PROJECT_PARTS_TO_PRINT_STARTED'
export const CHANGE_PARTS_PAGE = 'CHANGE_PARTS_PAGE'
export const GET_PROJECT_FEA_ANALYSIS_SUCCESS =
	'GET_PROJECT_FEA_ANALYSIS_SUCCESS'
export const PROJECT_PART_WEIGHT_REDUCTION_SENT =
	'PROJECT_PART_WEIGHT_REDUCTION_SENT'
export const PROJECT_PART_WEIGHT_REDUCTION_CANCELLED =
	'PROJECT_PART_WEIGHT_REDUCTION_CANCELLED'
export const MORE_PROJECT_PART_WEIGHT_REDUCTION =
	'MORE_PROJECT_PART_WEIGHT_REDUCTION'
export const PROJECT_FEA_ANALYSIS_CANCELLED = 'PROJECT_FEA_ANALYSIS_CANCELLED'
export const PROJECT_FEA_ANALYSIS_LOADER_TOGGLED =
	'PROJECT_FEA_ANALYSIS_LOADER_TOGGLED'
export const PROJECT_FEA_ANALYSIS_DATA_FETCHED =
	'PROJECT_FEA_ANALYSIS_DATA_FETCHED'
export const GET_PROJECT_ANALYSIS_FAILED = 'GET_PROJECT_ANALYSIS_FAILED'
export const GET_PROJECT_ANALYSIS_NO_PERMISSION =
	'GET_PROJECT_ANALYSIS_NO_PERMISSION'
//part analysis
export const PART_ANALYSIS_CONSTRUCTOR = 'PART_ANALYSIS_CONSTRUCTOR'
export const GET_PART_ANALYSIS = 'GET_PART_ANALYSIS'
export const GET_PART_ANALYSIS_SUCCESS = 'GET_PART_ANALYSIS_SUCCESS'
export const GET_PART_ANALYSIS_FAILED = 'GET_PART_ANALYSIS_FAILED'
export const PART_UPDATED = 'PART_UPDATED'
export const HEATMAP_SWITCH_VALUE_CHANGED = 'HEATMAP_SWITCH_VALUE_CHANGED'
export const PART_ANALYSIS_WILL_UNMOUNT = 'PART_ANALYSIS_WILL_UNMOUNT'
export const PART_ANALYSIS_3D_MODAL_OPENED = 'PART_ANALYSIS_3D_MODAL_OPENED'
export const PART_ANALYSIS_3D_MODAL_CLOSED = 'PART_ANALYSIS_3D_MODAL_CLOSED'
export const PART_ANALYSIS_RETRIEVE_RESULTS_CLICKED =
	'PART_ANALYSIS_RETRIEVE_RESULTS_CLICKED'
export const CONFIGURATION_CALCULATED = 'CONFIGURATION_CALCULATED'
//part tech analysis
export const SETUP_TECH_ANALYISIS_COMPONENT = 'SETUP_TECH_ANALYISIS_COMPONENT'
export const SHOW_PRINTING_MATERIAL_ALERT = 'SHOW_TECHNOLOGY_ALERT'
export const MATERIAL_CHANGE = 'MATERIAL_CHANGE'
export const AMOUNT_CHANGED = 'AMOUNT_CHANGED'
export const RESULT_RECALCULATED_SUCCESS = 'RESULT_RECALCULATED_SUCCESS'
export const RESULT_RECALCULATED_FAILED = 'RESULT_RECALCULATED_FAILED'
export const PROJECT_UPDATE_FAILED = 'PROJECT_UPDATE_FAILED'
export const PROJECT_QUANTITY_AMOUNT_CHANGED = 'PROJECT_QUANTITY_AMOUNT_CHANGED'
export const PROJECT_UPDATE_SENT = 'PROJECT_UPDATE_SENT'
export const PROJECT_ANALYSIS_SHOW_ALERT_FOR_FAILED_PART =
	'PROJECT_ANALYSIS_SHOW_ALERT_FOR_FAILED_PART'
export const PROJECT_WEIGHT_REDUCTION_CHANGED =
	'PROJECT_WEIGHT_REDUCTION_CHANGED'
export const PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED =
	'PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED'
export const PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED =
	'PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED'
export const PROJECT_WEIGHT_REDUCTION_SUBMITED =
	'PROJECT_WEIGHT_REDUCTION_SUBMITED'
export const PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED =
	'PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED'
export const PROJECT_WEIGHT_REDUCTION_UPDATED =
	'PROJECT_WEIGHT_REDUCTION_UPDATED'
export const PROJECT_ANALYSIS_REMOVE_ALERT = 'PROJECT_ANALYSIS_REMOVE_ALERT'
export const PROJECT_ANALYSIS_REMOVE_PART_CLICKED =
	'PROJECT_ANALYSIS_REMOVE_PART_CLICKED'
export const PROJECT_ANALYSIS_REMOVE_PART_CANCELED =
	'PROJECT_ANALYSIS_REMOVE_PART_CANCELED'
export const PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED =
	'PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED'
export const PROJECT_ANALYSIS_PART_REMOVED = 'PROJECT_ANALYSIS_PART_REMOVED'
export const PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR =
	'PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR'
export const PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED =
	'PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED'
export const PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED =
	'PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED'
export const PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED =
	'PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED'
export const PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED =
	'PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED'
export const PROJECT_ANALYSIS_ADD_TOUR_STEPS = 'PROJECT_ANALYSIS_ADD_TOUR_STEPS'
export const PROJECT_ANALYSIS_SHOW_TOUR_UPDATED =
	'PROJECT_ANALYSIS_SHOW_TOUR_UPDATED'

export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const METRICS_CHANGED_IN_TECH_ANALYSIS =
	'METRICS_CHANGED_IN_TECH_ANALYSIS'
export const METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL =
	'METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL'
export const SAME_MATERIAL_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL =
	'SAME_MATERIAL_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL'
export const IN_HOUSE_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL =
	'IN_HOUSE_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL'
export const SHOW_LEAD_TIME_ALERT = 'SHOW_LEAD_TIME_ALERT'
export const SHOW_TECHNOLOGY_ALERT = 'SHOW_TECHNOLOGY_ALERT'
export const SHOW_PRIORITY_ALERT = 'SHOW_PRIORITY_ALERT'
export const SHOW_PRICE_ESTIMATE_ALERT = 'SHOW_PRICE_ESTIMATE_ALERT'
export const SHOW_WEIGHT_RESULT_ALERT = 'SHOW_WEIGHT_RESULT_ALERT'
export const SHOW_MATERIAL_ALERT = 'SHOW_MATERIAL_ALERT'
export const SHOW_PRINTER_ALERT = 'SHOW_PRINTER_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const HIDE_VERIFY_USER_ALERT = 'HIDE_VERIFY_USER_ALERT'
export const HIDE_PASSWORD_REQUEST_ALERT = 'HIDE_PASSWORD_REQUEST_ALERT'
export const RESET_TOKEN_SAVED = 'RESET_TOKEN_SAVED'
export const HIDE_FORGOT_ALERT = 'HIDE_FORGOT_ALERT'
export const HIDE_RESET_PASSWORD_ERROR_ALERT = 'HIDE_RESET_PASSWORD_ERROR_ALERT'
export const MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS =
	'MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS'
export const MATERIAL_CHANGED_IN_TECH_ANALYSIS =
	'MATERIAL_CHANGED_IN_TECH_ANALYSIS'
export const IN_HOUSE_SWITCH_CHANGED = 'IN_HOUSE_SWITCH_CHANGED'
export const PART_UPDATED_IN_TECH_ANALYSIS = 'PART_UPDATED_IN_TECH_ANALYSIS'
export const AMOUNT_CHANGED_IN_TECH_ANALYSIS = 'AMOUNT_CHANGED_IN_TECH_ANALYSIS'
//project clusters overview
export const GET_PROJECT_CLUSTERS_SUCCESS = 'GET_PROJECT_CLUSTERS_SUCCESS'
export const GET_PROJECT_CLUSTERS = 'GET_PROJECT_CLUSTERS'
export const GET_PROJECT_CLUSTERS_FAILED = 'GET_PROJECT_CLUSTERS_FAILED'
//cluster analysis
export const GET_CLUSTER_ANALYSIS_SUCCESS = 'GET_CLUSTER_ANALYSIS_SUCCESS'
export const GET_CLUSTER_ANALYSIS = 'GET_CLUSTER_ANALYSIS'
export const GET_CLUSTER_ANALYSIS_FAILED = 'GET_CLUSTER_ANALYSIS_FAILED'
//cluster tech analysis
export const CLUSTER_MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS =
	'CLUSTER_MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS'
export const SETUP_TECH_ANALYISIS_CLUSTER_COMPONENT =
	'SETUP_TECH_ANALYISIS_CLUSTER_COMPONENT'
export const CLUSTER_UPDATED = 'CLUSTER_UPDATED'
export const CLUSTER_RESULT_RECALCULATED_SUCCESS =
	'CLUSTER_RESULT_RECALCULATED_SUCCESS'
export const CLUSTER_RESULT_RECALCULATED_FAILED =
	'CLUSTER_RESULT_RECALCULATED_FAILED'
export const CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS =
	'CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS'
export const CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL =
	'CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL'
export const CLUSTER_MATERIAL_CHANGED_IN_TECH_ANALYSIS =
	'CLUSTER_MATERIAL_CHANGED_IN_TECH_ANALYSIS'

//MultiplePartsIntoOne request widgit
export const MPIO_SHOW_ALERT = 'MPIO_SHOW_ALERT'
export const MPIO_REQUEST = 'MPIO_REQUEST'
export const MPIO_REQUEST_SUCCESS = 'MPIO_REQUEST_SUCCESS'
export const MPIO_REQUEST_FAIL = 'MPIO_REQUEST_FAIL'
//part financial analysis
export const MOLDED_PART_PRICE_CHANGED = 'MOLDED_PART_PRICE_CHANGED'
export const GO_TO_EDIT_MODE = 'GO_TO_EDIT_MODE'
export const EDIT_MODE_CANCELLED = 'EDIT_MODE_CANCELLED'
export const SETUP_FINANCIAL_ANALYSIS_COMPONENT =
	'SETUP_FINANCIAL_ANALYSIS_COMPONENT'
export const FINANCIAL_ANALYSIS_COMPONENT_PLASTIC_SUBMITED =
	'FINANCIAL_ANALYSIS_COMPONENT_PLASTIC_SUBMITED'
export const SETUP_FINANCIAL_ANALYSIS_FINISHED =
	'SETUP_FINANCIAL_ANALYSIS_FINISHED'

export const SETUP_COMPONENT = 'SETUP_COMPONENT'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS'
export const SUBMIT_FORM_FAILED = 'SUBMIT_FORM_FAILED'

//part requirements
export const ACCURACY_CHECKBOX_CLICKED = 'ACCURACY_CHECKBOX_CLICKED'
export const OFFTHESHELF_CHECKBOX_CLICKED = 'OFFTHESHELF_CHECKBOX_CLICKED'
export const SURFACE_FINISH_CHECKBOX_CLICKED = 'SURFACE_FINISH_CHECKBOX_CLICKED'

export const ACCURACY_CHECKBOX_UPDATED = 'ACCURACY_CHECKBOX_UPDATED'
export const OFFTHESHELF_CHECKBOX_UPDATED = 'OFFTHESHELF_CHECKBOX_UPDATED'
export const SURFACE_FINISH_CHECKBOX_UPDATED = 'SURFACE_FINISH_CHECKBOX_UPDATED'

//customize user
export const USER_DESKTOP_MATERIAL_TOGGLED = 'USER_DESKTOP_MATERIAL_TOGGLED'
export const USER_UNITS_TYPE_SELECTED = 'USER_UNITS_TYPE_SELECTED'
export const IN_HOUSE_PRINTERS_DATA_RECEIVED =
		'IN_HOUSE_PRINTERS_DATA_RECEIVED',
	IN_HOUSE_PRINTERS_COMPANY_SELECTED = 'IN_HOUSE_PRINTERS_COMPANY_SELECTED',
	IN_HOUSE_PRINTERS_NAME_SELECTED = 'IN_HOUSE_PRINTERS_NAME_SELECTED',
	IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED =
		'IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED',
	IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED =
		'IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED',
	IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED =
		'IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED',
	IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED =
		'IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED',
	IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED =
		'IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED',
	IN_HOUSE_OTHER_PLUS_BUTTON_CLICK = 'IN_HOUSE_OTHER_PLUS_BUTTON_CLICK',
	IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED =
		'IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED',
	IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED =
		'IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED',
	IN_HOUSE_PRINTERS_AMOUNT_CHANGED = 'IN_HOUSE_PRINTERS_AMOUNT_CHANGED',
	IN_HOUSE_PRINTERS_PRINTER_SUBMITTED = 'IN_HOUSE_PRINTERS_PRINTER_SUBMITTED',
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_SELECTED =
		'IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_SELECTED',
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED =
		'IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED',
	IN_HOUSE_PRINTERS_PRINTER_GOT_ERROR = 'IN_HOUSE_PRINTERS_PRINTER_GOT_ERROR',
	IN_HOUSE_PRINTERS_PRINTER_SUCCESS = 'IN_HOUSE_PRINTERS_PRINTER_SUCCESS',
	IN_HOUSE_PRINTERS_PRINTER_ADDED = 'IN_HOUSE_PRINTERS_PRINTER_ADDED',
	IN_HOUSE_PRINTERS_PRINTER_REMOVED = 'IN_HOUSE_PRINTERS_PRINTER_REMOVED',
	IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED =
		'IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED',
	IN_HOUSE_PRINTERS_USER_ID_SELECTED = 'IN_HOUSE_PRINTERS_USER_ID_SELECTED',
	IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER =
		'IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER',
	IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED =
		'IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED',
	IN_HOUSE_PRINTERS_ALERT_OPEN = 'IN_HOUSE_PRINTERS_ALERT_OPEN',
	IN_HOUSE_PRINTERS_ALERT_CLOSE = 'IN_HOUSE_PRINTERS_ALERT_CLOSE'

export const USER_MATERIALS_USER_ID_SELECTED =
		'USER_MATERIALS_USER_ID_SELECTED',
	USER_PRINTERS_RECIEVED = 'USER_PRINTERS_RECIEVED',
	USER_PRINTERS_USER_ID_SELECTED = 'USER_PRINTERS_USER_ID_SELECTED',
	USER_PRINTERS_MATERIAL_RECIEVED = 'USER_PRINTERS_MATERIAL_RECIEVED',
	USER_PRINTERS_MATERIAL_USER_ID_SELECTED =
		'USER_PRINTERS_MATERIAL_USER_ID_SELECTED',
	USER_MATERIALS_RECIEVED = 'USER_MATERIALS_RECIEVED',
	USER_MATERIALS_RESET_DATA = 'USER_MATERIALS_RESET_DATA',
	MATERIAL_CATEGORIES_RECIEVED = 'MATERIAL_CATEGORIES_RECIEVED',
	USER_MATERIALS_MATERIAL_REMOVED = 'USER_MATERIALS_MATERIAL_REMOVED',
	USER_PRINTERS_MATERIAL_REMOVED = 'USER_PRINTERS_MATERIAL_REMOVED',
	USER_PRINTER_MATERIALS_MATERIAL_REMOVED =
		'USER_PRINTER_MATERIALS_MATERIAL_REMOVED',
	USER_PRINTER_NEW_COMPANY_SELECTED = 'USER_PRINTER_NEW_COMPANY_SELECTED',
	USER_MATERIALS_DELETE_CLICKED = 'USER_MATERIALS_DELETE_CLICKED',
	USER_MATERIALS_DELETE_CANCELED = 'USER_MATERIALS_DELETE_CANCELED',
	MATERIAL_REMOVED_FROM_USER = 'MATERIAL_REMOVED_FROM_USER',
	USER_PRINTER_REMOVED_FROM_USER = 'USER_PRINTER_REMOVED_FROM_USER',
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER =
		'USER_PRINTER_MATERIAL_REMOVED_FROM_USER',
	USER_MATERIALS_USER_MATERIALS_FORMATED =
		'USER_MATERIALS_USER_MATERIALS_FORMATED',
	USER_MATERIALS_MATERIAL_SUBMITED = 'USER_MATERIALS_MATERIAL_SUBMITED',
	USER_MATERIALS_MATERIAL_ADDED_TO_USER =
		'USER_MATERIALS_MATERIAL_ADDED_TO_USER',
	USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE =
		'USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE',
	USER_PRINTERS_ADDED = 'USER_PRINTERS_ADDED',
	MATERIAL_ADDED_TO_USER = 'MATERIAL_ADDED_TO_USER',
	NEW_PRINTER_ADDED_TO_USER = 'NEW_PRINTER_ADDED_TO_USER',
	USER_MATERIALS_MATERIAL_ADDED = 'USER_MATERIALS_MATERIAL_ADDED',
	USER_MATERIALS_MATERIAL_ADD_FAILED = 'USER_MATERIALS_MATERIAL_ADD_FAILED',
	NEW_MATERIAL_FORM_LOADED = 'NEW_MATERIAL_FORM_LOADED',
	USER_MATERIALS_MATERIAL_TYPE_CHANGED = 'USER_MATERIALS_MATERIAL_TYPE_CHANGED'

export const ADD_MATERIALS_BOM_FILE = 'ADD_MATERIALS_BOM_FILE'
export const ADD_MATERIALS_BOM_LOADING = 'ADD_MATERIALS_BOM_LOADING'
export const ADD_MATERIALS_BOM_POPUP = 'ADD_MATERIALS_BOM_POPUP'

export const OFF_THE_SHELF_SUBSTRING_CHANGE = 'OFF_THE_SHELF_SUBSTRING_CHANGE'
export const SAVE_PART_FILTERING_ERROR = 'SAVE_PART_FILTERING_ERROR'
export const OFF_THE_SHELF_SLECTED_ITEM_CHANGE =
	'OFF_THE_SHELF_SLECTED_ITEM_CHANGE'
export const COST_AND_LEAD_TBL_DATA_ARRANGED = 'COST_AND_LEAD_TBL_DATA_ARRANGED'
export const COST_AND_LEAD_TBL_DATA_UPDATED = 'COST_AND_LEAD_TBL_DATA_UPDATED'
export const COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED =
	'COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED'
export const FULL_TRAY_ASSUMPTION_DATA_ARRANGED =
	'FULL_TRAY_ASSUMPTION_DATA_ARRANGED'
export const UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED =
	'UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED'
export const UPLOAD_PROJECT_SCENARIO_DATA_EDIT =
	'UPLOAD_PROJECT_SCENARIO_DATA_EDIT'
export const UPLOAD_PROJECT_SCENARIO_DATA_APPLIED =
	'UPLOAD_PROJECT_SCENARIO_DATA_APPLIED'
export const UPLOAD_PROJECT_SCENARIO_DATA_CANCEL =
	'UPLOAD_PROJECT_SCENARIO_DATA_CANCEL'
export const UPLOAD_PROJECT_SCENARIO_DATA_CHANGE =
	'UPLOAD_PROJECT_SCENARIO_DATA_CHANGE'
export const UPLOAD_PROJECT_SCENARIO_DATA_STARTED =
	'UPLOAD_PROJECT_SCENARIO_DATA_STARTED'
export const UPLOAD_PROJECT_SCENARIO_DATA_ENDED =
	'UPLOAD_PROJECT_SCENARIO_DATA_ENDED'
export const UPLOAD_PROJECT_SCENARIO_DATA_ERROR =
	'UPLOAD_PROJECT_SCENARIO_DATA_ERROR'
export const PART_FILTERING_FORM_DATA_ARRANGED =
	'PART_FILTERING_FORM_DATA_ARRANGED'
export const COST_AND_LEAD_INPUTS_CHANGED = 'COST_AND_LEAD_INPUTS_CHANGED'
export const VALUE_CHANGED_TO_EDIT = 'VALUE_CHANGED_TO_EDIT'
export const USER_SETTING_CHANGED = 'USER_SETTING_CHANGED'
export const USER_SETTING_GOT_ERROR = 'USER_SETTING_GOT_ERROR'
export const EDIT_USER_SETTING_CANCELED = 'EDIT_USER_SETTING_CANCELED'
export const CUSTOMIZE_BUTTON_LOADER_UPDATED = 'CUSTOMIZE_BUTTON_LOADER_UPDATED'
export const CUSTOMIZE_PRINTER_TECH_CHANGED = 'CUSTOMIZE_PRINTER_TECH_CHANGED'
export const CUSTOMIZE_MATERIAL_NAME_CHANGED = 'CUSTOMIZE_MATERIAL_NAME_CHANGED'
export const CUSTOMIZE_MATERIAL_PRICE_CHANGED =
	'CUSTOMIZE_MATERIAL_PRICE_CHANGED'
export const CUSTOMIZE_MATERIAL_COST_ADDED = 'CUSTOMIZE_MATERIAL_COST_ADDED'
export const CUSTOMIZE_MATERIAL_COST_DELETED = 'CUSTOMIZE_MATERIAL_COST_DELETED'
export const CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED =
	'CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED'
export const CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_UPDATED =
	'CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_UPDATE'
export const CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED =
	'CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED'
export const MATERIAL_COST_ALERT_UPDATED = 'MATERIAL_COST_ALERT_UPDATED'
export const CUSTOMIZE_PRINTER_PROPS_SETUPED = 'CUSTOMIZE_PRINTER_PROPS_SETUPED'

export const CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED =
	'CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED'
export const CUSTOMIZE_PRINTER_PARAMETERS_CHANGED =
	'CUSTOMIZE_PRINTER_PARAMETERS_CHANGED'
export const CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED =
	'CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED'
export const CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED =
	'CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED'
export const CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED =
	'CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED'
export const CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_FOR_INVALID_NUMBERS =
	'CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_FOR_INVALID_NUMBERS'
export const CUSTOMIZE_USER_SETTINGS_ADDED = 'CUSTOMIZE_USER_SETTINGS_ADDED'
export const CUSTOMIZE_PRINTER_SETTINGS_DELETED =
	'CUSTOMIZE_PRINTER_SETTINGS_DELETED'
export const CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_LAYER_THICKNESS__MORE_THAN_THOUSAND =
	'CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_LAYER_THICKNESS__MORE_THAN_THOUSAND'
export const CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_LAYER_THICKNESS_LESS_THAN_TEN =
	'CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_LAYER_THICKNESS_LESS_THAN_TEN'
export const CUSTOMIZE_PRINTER_PROPS_DELETE_ERROR_MESSAGE_FOR_LAYER_THICKNESS =
	'CUSTOMIZE_PRINTER_PROPS_DELETE_ERROR_MESSAGE_FOR_LAYER_THICKNESS'
export const CUSTOMIZE_BUTTON_ACTIVE = 'CUSTOMIZE_BUTTON_ACTIVE'
export const CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_PRICE__MORE_THAN_TEN_MILLION =
	'CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_PRICE__MORE_THAN_TEN_MILLION'
export const CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_PRICE_LESS_THAN_HUNDRED =
	'CUSTOMIZE_PRINTER_PROPS_ERROR_MESSAGE_PRICE_LESS_THAN_HUNDRED'
export const CUSTOMIZE_PRINTER_PROPS_DELETE_ERROR_MESSAGE_FOR_PRICE =
	'CUSTOMIZE_PRINTER_PROPS_DELETE_ERROR_MESSAGE_FOR_PRICE'
export const CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED_FOR_INVALID_NUMBERS =
	'CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED_FOR_INVALID_NUMBERS'
export const CUSTOMIZE_PRINTER_PROPS_PRINTER_THICKNESS_IS_ZERO =
	'CUSTOMIZE_PRINTER_PROPS_PRINTER_THICKNESS_IS_ZERO'

export const VALIDATION_STATE_CHANGED = 'VALIDATION_STATE_CHANGED'
export const USER_SETTING_CHANGED_TEMP = 'USER_SETTING_CHANGED_TEMP'
export const FIELD_CHANGED_TO_EDIT = 'FIELD_CHANGED_TO_EDIT'

//user profile
export const SETUP_PROFILE_DETAILS = 'SETUP_PROFILE_DETAILS'
export const USER_PROFILE_NAME_CHANGED = 'USER_PROFILE_NAME_CHANGED'
export const USER_PROFILE_EMAIL_CHANGED = 'USER_PROFILE_EMAIL_CHANGED'
export const USER_PROFILE_COMPANY_CHANGED = 'USER_PROFILE_COMPANY_CHANGED'
export const USER_PROFILE_ORGANIZATION_CHANGED =
	'USER_PROFILE_ORGANIZATION_CHANGED'
export const USER_PROFILE_ADDRESS_CHANGED = 'USER_PROFILE_ADDRESS_CHANGED'
export const USER_PROFILE_GET_ALL_ADDRESSES = 'USER_PROFILE_GET_ALL_ADDRESSES'
export const USER_PROFILE_SETUP_ADDRESSES = 'USER_PROFILE_SETUP_ADDRESSES'
export const USER_PROFILE_GET_ALL_ADDRESSES_ERROR =
	'USER_PROFILE_GET_ALL_ADDRESSES_ERROR'
export const USER_PROFILE_GET_ALL_ADDRESSES_LOADING =
	'USER_PROFILE_GET_ALL_ADDRESSES_LOADING'
// export const USER_PROFILE_ADDRESS_SELECTED = 'USER_PROFILE_ADDRESS_SELECTED'
export const USER_PROFILE_LOADER = 'USER_PROFILE_LOADER'
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED'

// castor location search
export const USER_PROFILE_ADDRESS_SELECTED = 'USER_PROFILE_ADDRESS_SELECTED'
export const LOCATION_ADDRESS_CHANGED = 'LOCATION_ADDRESS_CHANGED'
export const DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE =
	'DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE'
export const ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE =
	'ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE'

//castor notification
export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION'
export const NOTIFICATION_HAD_SHOWN = 'NOTIFICATION_HAD_SHOWN'
export const NOTIFICATION_CLEARED = 'NOTIFICATION_CLEARED'

//castor boundary
export const SHOW_BOUNDARY_ERROR = 'SHOW_BOUNDARY_ERROR'

//castor ActionAlert
export const ACTION_ALERT_CLOSED = 'ACTION_ALERT_CLOSED'
export const ACTION_ALERT_OPENED = 'ACTION_ALERT_OPENED'
export const ACTION_ALERT_APPROVED = 'ACTION_ALERT_APPROVED'
export const ACTION_ALERT_CANCELED = 'ACTION_ALERT_CANCELED'

//castor popper menu
export const POPPER_MENU_CLICKED = 'POPPER_MENU_CLICKED'
export const POPPER_MENU_CLOSED = 'POPPER_MENU_CLOSED'

//wall thickness on demand
export const WALL_THICKNESS_CLICKED = 'WALL_THICKNESS_CLICKED'
export const WALL_THICKNESS_ALERT_CANCELED = 'WALL_THICKNESS_ALERT_CANCELED'
export const WALL_THICKNESS_INPUT_CHANGED = 'WALL_THICKNESS_INPUT_CHANGED'
export const WALL_THICKNESS_UPDATED = 'WALL_THICKNESS_UPDATED'
export const WALL_THICKNESS_CONFIRMED = 'WALL_THICKNESS_CONFIRMED'
export const WALL_THICKNESS_POLLER_FINISHED = 'WALL_THICKNESS_POLLER_FINISHED'

//castor locked parts cars

export const CASTOR_LOCKED_PARTS_SENDING_REQUEST =
	'CASTOR_LOCKED_PARTS_SENDING_REQUEST'
export const CASTOR_LOCKED_PARTS_SET_TEXT = 'CASTOR_LOCKED_PARTS_SET_TEXT'
export const CASTOR_LOCKED_PARTS_REQUEST_SENT =
	'CASTOR_LOCKED_PARTS_REQUEST_SENT'

// user panel
export const USER_PANEL_DATA_FETCHED = 'USER_PANEL_DATA_FETCHED'
export const USER_PANEL_UNMOUNTED = 'USER_PANEL_UNMOUNTED'
export const USER_PANEL_PRINTING_SAVING_SELECT_CHANGED =
	'USER_PANEL_PRINTING_SAVING_SELECT_CHANGED'
export const USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED =
	'USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED'
export const USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED =
	'USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED'
export const USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED =
	'USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED'
export const USER_PANEL_PRINTING_SAVING_DATA_FETCHED =
	'USER_PANEL_PRINTING_SAVING_DATA_FETCHED'
export const USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR =
	'USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR'
export const USER_PANEL_ROI_TYPE_SELECT_CHANGED =
	'USER_PANEL_ROI_TYPE_SELECT_CHANGED'
export const USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED =
	'USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED'
export const USER_PANEL_ROI_TYPE_ALERT_CANCELLED =
	'USER_PANEL_ROI_TYPE_ALERT_CANCELLED'
export const USER_PANEL_ROI_TYPE_ALERT_CONFIRMED =
	'USER_PANEL_ROI_TYPE_ALERT_CONFIRMED'
export const USER_PANEL_ROI_CALCULATION_STARTED =
	'USER_PANEL_ROI_CALCULATION_STARTED'
export const USER_PANEL_ROI_CALCULATION_FINISHED =
	'USER_PANEL_ROI_CALCULATION_FINISHED'
export const USER_PANEL_ROI_CALCULATION_GOT_ERROR =
	'USER_PANEL_ROI_CALCULATION_GOT_ERROR'
export const USER_PANEL_ROI_PRINTER_COMPANY_CHANGED =
	'USER_PANEL_ROI_PRINTER_COMPANY_CHANGED'
export const USER_PANEL_ROI_PRINTER_CHANGED = 'USER_PANEL_ROI_PRINTER_CHANGED'

export const SEND_PROJECT_FAILED_TO_ADMINS = 'SEND_PROJECT_FAILED_TO_ADMINS'

export const UPDATE_SHOW_TOUR = 'UPDATE_SHOW_TOUR'
export const UPDATE_TOUR_FAILED = 'UPDATE_TOUR_FAILED'
export const UPLOAD_PROJECT_UPDATE_TOUR_STEPS =
	'UPLOAD_PROJECT_UPDATE_TOUR_STEPS'
export const USER_CUSTOM_SETTINGS_UPDATED = 'USER_CUSTOM_SETTINGS_UPDATED'
export const CHAIN_BENEFITS_FORM_EDIT_CANCELLED =
	'CHAIN_BENEFITS_FORM_EDIT_CANCELLED'
export const OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP =
	'OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP'
export const CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP =
	'CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP'
export const OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS =
	'OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP'
export const CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS =
	'CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP'
export const WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT =
	'WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT'
export const GRAB_CAD_PRINT_UPDATED = 'GRAB_CAD_PRINT_UPDATED'

export const FORGOT_PASSWORD_POPUP_CLOSE = 'FORGOT_PASSWORD_POPUP_CLOSE'
export const MAINTENANCE_FIELDS_SELECTOR_CHANGED =
	'MAINTENANCE_FIELDS_SELECTOR_CHANGED'
export const MACHINE_COST_FIELDS_SELECTOR_CHANGED =
	'MACHINE_COST_FIELDS_SELECTOR_CHANGED'
export const USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED =
	'USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED'
export const PARAMETERS_MANAGER_INJECTION_COSTING_ON_SUBMIT =
	'PARAMETERS_MANAGER_INJECTION_COSTING_ON_SUBMIT'
export const PARAMETERS_MANAGER_COSTING_3DP_ON_SUBMIT =
	'PARAMETERS_MANAGER_COSTING_3DP_ON_SUBMIT'
export const PARAMETERS_MANAGER_COSTING_GENERAL_ON_SUBMIT =
	'PARAMETERS_MANAGER_COSTING_GENERAL_ON_SUBMIT'

export const PARAMETERS_MANAGER_COSTING_CNC_ON_SUBMIT =
	'PARAMETERS_MANAGER_COSTING_CNC_ON_SUBMIT'

export const PARAMETERS_MANAGER_DATA_CALLED = 'PARAMETERS_MANAGER_DATA_CALLED'
export const PARAMETERS_MANAGER_DATA_FETCHED = 'PARAMETERS_MANAGER_DATA_FETCHED'
export const UPDATING_PARAMTER = 'UPDATING_PARAMTER'
export const PARAMTER_UPDATE_SUCCEED = 'PARAMTER_UPDATE_SUCCEED'
export const MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED =
	'MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED'
export const USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED =
	'USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED'

export const RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG =
	'RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG'
export const AUTHORIZATION_TOKEN_UPDATED = 'AUTHORIZATION_TOKEN_UPDATED'
export const AUTHORIZATION_GET_PROVIDERS_ERROR =
	'AUTHORIZATION_GET_PROVIDERS_ERROR'
export const AUTHORIZATION_GET_PROVIDERS_SUCCESS =
	'AUTHORIZATION_GET_PROVIDERS_SUCCESS'
export const REFRESH_TOKEN_UPDATED = 'REFRESH_TOKEN_UPDATED'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN'
export const SOLUTION_CONFIGURATION_WAS_CHANGED =
	'SOLUTION_CONFIGURATION_WAS_CHANGED'
export const PROJECT_ANALYSIS_CLUSTER_REMOVED =
	'PROJECT_ANALYSIS_CLUSTER_REMOVED'

export const GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS =
	'GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS'
export const GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS =
	'GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS'
export const GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR =
	'GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR'
export const GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR =
	'GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR'
export const GET_ADMIN_3D_TECH_PARAMETERS = 'GET_ADMIN_3D_TECH_PARAMETERS'
export const GET_ADMIN_VERSION_PAGE_PARAMETERS =
	'GET_ADMIN_VERSION_PAGE_PARAMETERS'

export const GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED =
	'GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED'

export const ADMIN_3D_TECH_PARAMETERS_UPDATED =
	'ADMIN_3D_TECH_PARAMETERS_UPDATED'

export const EXPORT_PROJECT = 'EXPORT_PROJECT'
export const CHECK_SYSTEM_PARAMETERS = 'CHECK_SYSTEM_PARAMETERS'

export const UPLOAD_SCRIPT_FILE_START = 'UPLOAD_SCRIPT_FILE_START'
export const UPLOAD_LICENSE_FILE_START = 'UPLOAD_LICENSE_FILE_START'
export const UPLOAD_LICENSE_FILE_END = 'UPLOAD_LICENSE_FILE_END'
export const UPLOAD_SCRIPT_FILE_FINISH = 'UPLOAD_SCRIPT_FILE_FINISH'
export const UPLOAD_LICENSE_FILE_FINISH = 'UPLOAD_LICENSE_FILE_FINISH'
export const UPLOAD_SCRIPT_ADD_FILE = 'UPLOAD_SCRIPT_ADD_FILE'
export const UPLOAD_LICENSE_ADD_FILE = 'UPLOAD_LICENSE_ADD_FILE'
export const UPLOAD_SCRIPT_ERROR = 'UPLOAD_SCRIPT_ERROR'
export const UPLOAD_LICENSE_ERROR = 'UPLOAD_LICENSE_ERROR'
export const GENERATE_LICENSE_ADD_FILE = 'GENERATE_LICENSE_ADD_FILE'
export const GENERATE_LICENSE_ERROR = 'GENERATE_LICENSE_ERROR'
export const GENERATE_LICENSE_FILE_END = 'GENERATE_LICENSE_FILE_END'
export const GENERATE_LICENSE_FILE_START = 'GENERATE_LICENSE_FILE_START'

export const GET_LICENSE_INFORMATION = 'GET_LICENSE_INFORMATION'
export const GET_LICENSE_INFORMATION_ERROR = 'GET_LICENSE_INFORMATION_ERROR'
export const GET_LICENSE_INFORMATION_SUCCESS = 'GET_LICENSE_INFORMATION_SUCCESS'

export const GET_ALL_PRINTERS_SUCCESS = 'GET_ALL_PRINTERS_SUCCESS'
export const GET_ALL_PRINTERS_ERROR = 'GET_ALL_PRINTERS_ERROR'
export const GET_ALL_PRINTERS_LOADING = 'GET_ALL_PRINTERS_LOADING'

export const USER_FILTERS_TOGGLES_UPDATED = 'USER_FILTERS_TOGGLES_UPDATED'
export const USER_FILTERS_CHANGE = 'USER_FILTERS_CHANGE'

export const UPDATE_USER_MATERIAL_NAMES_MAPPING =
	'UPDATE_USER_MATERIAL_NAMES_MAPPING'
export const START_UPDATING_USER_MATERIAL_NAMES_MAPPING =
	'START_UPDATING_USER_MATERIAL_NAMES_MAPPING'
export const CREATE_USER_MATERIAL_NAMES_MAPPING =
	'CREATE_USER_MATERIAL_NAMES_MAPPING'
export const REMOVE_USER_MATERIAL_NAMES_MAPPING =
	'REMOVE_USER_MATERIAL_NAMES_MAPPING'
export const FATCH_USER_MATERIAL_NAMES_MAPPING =
	'FATCH_USER_MATERIAL_NAMES_MAPPING'
export const FATCH_MULTIPLE_USER_MATERIAL_NAMES_MAPPING =
	'FATCH_MULTIPLE_USER_MATERIAL_NAMES_MAPPING'
export const CHANGE_USER_MATERIAL_NAME_MAPPING =
	'CHANGE_USER_MATERIAL_NAME_MAPPING'

export const PROJECT_PARTS_PROPERTIES_FETCHED =
	'PROJECT_PARTS_PROPERTIES_FETCHED'
export const PROJECT_PARTS_PROPERTIES_CALCULATING =
	'PROJECT_PARTS_PROPERTIES_CALCULATING'
export const PROJECT_PARTS_PROPERTIES_CALCULATING_RESET =
	'PROJECT_PARTS_PROPERTIES_CALCULATING_RESET'
export const PROJECT_PARTS_PROPERTIES_RESET = 'PROJECT_PARTS_PROPERTIES_RESET'
export const PROJECT_PARTS_PROPERTIES_UPDATE_PART =
	'PROJECT_PARTS_PROPERTIES_UPDATE_PART'
export const PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA =
	'PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA'

export const EMISSION_TBL_DATA_ARRANGED = 'EMISSION_TBL_DATA_ARRANGED'
export const EMISSION_EDIT_SELECTED_METHOD = 'EMISSION_EDIT_SELECTED_METHOD'
export const EMISSION_EDIT_ALL_METHODS = 'EMISSION_EDIT_ALL_METHODS'
export const EMISSION_DATA_ERROR_METHODS = 'EMISSION_DATA_ERROR_METHODS'
export const EMISSION_CHANGE_DATA_METHODS = 'EMISSION_CHANGE_DATA_METHODS'
export const EMISSION_UPDATED_LOADING_DATA_METHODS =
	'EMISSION_UPDATED_LOADING_DATA_METHODS'
export const EMISSION_UPDATED_SUCCESS_DATA_METHODS =
	'EMISSION_UPDATED_SUCCESS_DATA_METHODS'
export const EMISSION_UPDATED_FAILED_DATA_METHODS =
	'EMISSION_UPDATED_FAILED_DATA_METHODS'

export const DRAWING_COST_EDIT_TOGGLE = 'DRAWING_COST_EDIT_TOGGLE'
export const DRAWING_COST_EDIT_ALL_TOGGLE = 'DRAWING_COST_EDIT_ALL_TOGGLE'
export const DRAWING_COST_APPLY_TOGGLE = 'DRAWING_COST_APPLY_TOGGLE'
export const DRAWING_COST_UPDATE_DATA = 'DRAWING_COST_UPDATE_DATA'
export const DRAWING_COST_EDIT_DATA = 'DRAWING_COST_EDIT_DATA'
export const DRAWING_COST_UPDATE_ALL_DATA = 'DRAWING_COST_UPDATE_ALL_DATA'

export const METADATA_COST_EDIT_TOGGLE = 'METADATA_COST_EDIT_TOGGLE'
export const METADATA_COST_EDIT_ALL_TOGGLE = 'METADATA_COST_EDIT_ALL_TOGGLE'
export const METADATA_COST_APPLY_TOGGLE = 'METADATA_COST_APPLY_TOGGLE'
export const METADATA_COST_UPDATE_DATA = 'METADATA_COST_UPDATE_DATA'
export const METADATA_COST_EDIT_DATA = 'METADATA_COST_EDIT_DATA'
export const METADATA_COST_UPDATE_ALL_DATA = 'METADATA_COST_UPDATE_ALL_DATA'

export const FEATURE_TOGGLE_DATA_FETCHED = 'FEATURE_TOGGLE_DATA_FETCHED'
export const FEATURE_LIVE_ENVIRONMENT_UPDATED =
	'FEATURE_LIVE_ENVIRONMENT_UPDATED'
export const FEATURE_CONFIGURATION_UPDATED = 'FEATURE_CONFIGURATION_UPDATED'
export const FEATURE_CONFIGURATION_CREATED = 'FEATURE_CONFIGURATION_CREATED'

export const DEFAULT_SCENARIO_CHANGED = 'DEFAULT_SCENARIO_CHANGED'

export const DISPOSE_FACTOR_MATERIAL_CHANGED = 'DISPOSE_FACTOR_MATERIAL_CHANGED'
export const DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED =
	'DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED'
export const DISPOSE_FACTOR_NEW_MATERIAL_ADDED =
	'DISPOSE_FACTOR_NEW_MATERIAL_ADDED'
export const DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED =
	'DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED'

export const DISPOSE_FACTOR_MATERIAL_DELETED = 'DISPOSE_FACTOR_MATERIAL_DELETED'
export const DISPOSE_FACTOR_PRINTER_MATERIAL_DELETED =
	'DISPOSE_FACTOR_PRINTER_MATERIAL_DELETED'

export const CO2_PER_KG_MATERIAL_CHANGED = 'CO2_PER_KG_MATERIAL_CHANGED'
export const CO2_PER_KG_PRINTER_MATERIAL_CHANGED =
	'CO2_PER_KG_PRINTER_MATERIAL_CHANGED'
export const PRINTER_TYPICAL_KWH_CHANGED = 'PRINTER_TYPICAL_KWH_CHANGED'
export const TM_TYPICAL_KWH_CHANGED = 'TM_TYPICAL_KWH_CHANGED'

export const CO2_PER_KW_CHANGED = 'CO2_PER_KW_CHANGED'
export const CO2_HOLD_FACTOR_CHANGED = 'CO2_HOLD_FACTOR_CHANGED'
export const CO2_CARBON_CREDIT_COST_CHANGED = 'CO2_CARBON_CREDIT_COST_CHANGED'
export const YEARLY_SAVED_AMOUNT_CHANGED = 'YEARLY_SAVED_AMOUNT_CHANGED'
export const PART_LIFE_TIME_CHANGED = 'PART_LIFE_TIME_CHANGED'
export const END_OF_LIFE_DISPOSE_FACTOR_CHANGED =
	'END_OF_LIFE_DISPOSE_FACTOR_CHANGED'

export const GET_CO2_MATERIAL = 'GET_CO2_MATERIAL'
export const CO2_NEW_MATERIAL_ADDED = 'CO2_NEW_MATERIAL_ADDED'
export const CO2_MATERIAL_DELETED = 'CO2_MATERIAL_DELETED'
export const CO2_PRINTER_MATERIAL_DELETED = 'CO2_PRINTER_MATERIAL_DELETED'
export const CO2_PRINTER_TYPICAL_KWH_DELETED = 'CO2_PRINTER_TYPICAL_KWH_DELETED'
export const CO2_TM_TYPICAL_KWH_DELETED = 'CO2_TM_TYPICAL_KWH_DELETED'
export const CO2_USER_SETTINGS_DATA_LOADING = 'CO2_USER_SETTINGS_DATA_LOADING'

export const CO2_NEW_PRINTER_MATERIAL_ADDED = 'CO2_NEW_PRINTER_MATERIAL_ADDED'
export const CO2_NEW_PRINTER_TYPICAL_KWH_ADDED =
	'CO2_NEW_PRINTER_TYPICAL_KWH_ADDED'
export const CO2_NEW_TM_TYPICAL_KWH_ADDED = 'CO2_NEW_TM_TYPICAL_KWH_ADDED'

export const CO2_USER_SETTINGS_CARBON_CHANGED =
	'CO2_USER_SETTINGS_CARBON_CHANGED'
export const CO2_USER_SETTINGS_PU = 'CO2_USER_SETTINGS_PU'
export const CO2_USER_SETTINGS_DISPOSE = 'CO2_USER_SETTINGS_DISPOSE'
export const CO2_USER_SETTINGS_HOLD = 'CO2_USER_SETTINGS_HOLD'
export const CO2_USER_SETTINGS_CARBON_CREDIT_COST =
	'CO2_USER_SETTINGS_CARBON_CREDIT_COST'

export const CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED =
	'CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED'
export const CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED =
	'CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED'
export const CUSTOMIZE_PRINTER_MATERIAL_SETUP =
	'CUSTOMIZE_PRINTER_MATERIAL_SETUP'
export const REMOVE_ERROR_FROM_DROP_ZONE = 'REMOVE_ERROR_FROM_DROP_ZONE'
export const CHANGE_PARTS_VIEW = 'CHANGE_PARTS_VIEW'
export const TOGGLE_COSTING_FUNCTION_ACCORDION =
	'TOGGLE_COSTING_FUNCTION_ACCORDION'
export const FUNCTION_TYPE_SELECTED = 'FUNCTION_TYPE_SELECTED'
export const TOGGLE_FUNCTION_PRINTING_TECHNOLOGY =
	'TOGGLE_FUNCTION_PRINTING_TECHNOLOGY'
export const START_FETCHING_VARIABLES = 'START_FETCHING_VARIABLES'
export const ERROR_FETCHING_VARIABLES = 'ERROR_FETCHING_VARIABLES'
export const TECHNOLOGY_VARIABLES_FETCHED = 'TECHNOLOGY_VARIABLES_FETCHED'
export const ADD_VARIABLE_TO_FUNCTION = 'ADD_VARIABLE_TO_FUNCTION'
export const CLEAR_CUSTOM_FUNCTION = 'CLEAR_CUSTOM_FUNCTION'
export const DELETE_VARIABLE_FROM_FUNCTION = 'DELETE_VARIABLE_FROM_FUNCTION'
export const TOGGLE_PARAMETER_MODAL = 'TOGGLE_PARAMETER_MODAL'
export const SUCCESS_ADD_CUSTOM_VARIABLE = 'SUCCESS_ADD_CUSTOM_VARIABLE'
export const START_ADD_CUSTOM_VARIABLE = 'START_ADD_CUSTOM_VARIABLE'
export const ERROR_ADD_CUSTOM_VARIABLE = 'ERROR_ADD_CUSTOM_VARIABLE'
export const DELETE_CUSTOM_VARIABLE = 'DELETE_CUSTOM_VARIABLE'
export const START_SAVING_CUSTOM_FUNCTION = 'START_SAVING_CUSTOM_FUNCTION'
export const CUSTOM_FUNCTION_SUCCESS = 'CUSTOM_FUNCTION_SUCCESS'
export const CUSTOM_FUNCTION_FAIL = 'CUSTOM_FUNCTION_FAIL'
export const SETUP_COSTING_FUNCTION_EDITOR = 'SETUP_COSTING_FUNCTION_EDITOR'
export const DELETE_CUSTOM_FUNCTION = 'DELETE_CUSTOM_FUNCTION'
export const ROLL_BACK_TO_FIRST_STEP = 'ROLL_BACK_TO_FIRST_STEP'
export const SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS =
	'SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS'
export const UPDATE_USER_ONBOARDING_STATE = 'UPDATE_USER_ONBOARDING_STATE'
export const NEXT_STEP_LOADER = 'NEXT_STEP_LOADER'
export const IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED =
	'IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED'
export const IN_HOUSE_PRINTERS_UNMOUNT = 'IN_HOUSE_PRINTERS_UNMOUNT'
export const SETUP_PRINTER_COSTS = 'SETUP_PRINTER_COSTS'
export const EDITABLE_PRINTER_COSTS_CHANGE = 'EDITABLE_PRINTER_COSTS_CHANGE'
export const SETUP_MATERIAL_COSTS = 'SETUP_MATERIAL_COSTS'
export const EDITABLE_MATERIAL_COSTS_CHANGE = 'EDITABLE_MATERIAL_COSTS_CHANGE'
export const TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX =
	'TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX'
export const CHANGE_PARTS_PROPERTIES_PAGE = 'CHANGE_PARTS_PROPERTIES_PAGE'
export const GET_PARTS_PROPERTIES_LOADING = 'GET_PARTS_PROPERTIES_LOADING'
export const POST_PROCESS_UPDATED = 'POST_PROCESS_UPDATED'
export const USER_POST_PROCESSES_SET_UP = 'USER_POST_PROCESSES_SET_UP'
export const POST_PROCESS_CHANGED = 'POST_PROCESS_CHANGED'
export const NEW_POST_PROCESS_CHANGED = 'NEW_POST_PROCESS_CHANGED'
export const TOGGLE_FUNCTION_EDITOR_MODAL = 'TOGGLE_FUNCTION_EDITOR_MODAL'
export const USER_HOME_ROUTES_CHANGED = 'USER_HOME_ROUTES_CHANGED'
export const CHANGE_PROJECTS_SEARCH_PHRASE = 'CHANGE_PROJECTS_SEARCH_PHRASE'
export const TOGGLE_CREATE_PROJECTS_FOLDER = 'TOGGLE_CREATE_PROJECTS_FOLDER'
export const START_ADDING_NEW_FOLDER = 'START_ADDING_NEW_FOLDER'
export const END_ADDING_NEW_FOLDER = 'END_ADDING_NEW_FOLDER'
export const CHANGE_ORGANIZATION_NAME = 'CHANGE_ORGANIZATION_NAME'
export const CHANGE_ORGANIZATION_PRIVACY = 'CHANGE_ORGANIZATION_PRIVACY'
export const ORGANIZATION_INFO_UPDATED = 'ORGANIZATION_INFO_UPDATED'
export const UPDATE_ORGANIZATION_INFO_ERROR = 'UPDATE_ORGANIZATION_INFO_ERROR'
export const GOT_AUTH_FEATURES = 'GOT_AUTH_FEATURES'
export const SETUP_AVAILABLE_ORGANIZATIONS = 'SETUP_AVAILABLE_ORGANIZATIONS'
export const GET_ORGANIZATION_LICENSE_INFO = 'GET_ORGANIZATION_LICENSE_INFO'
export const CHOOSE_REGISTRATION_ORGANIZATION =
	'CHOOSE_REGISTRATION_ORGANIZATION'
export const SELECT_ORGANIZATION_TO_CUSTOMIZE =
	'SELECT_ORGANIZATION_TO_CUSTOMIZE'
export const RESET_SETTINGS_STATE = 'RESET_SETTINGS_STATE'
export const NEW_ORGANIZATION_ADDED = 'NEW_ORGANIZATION_ADDED'
export const SELECT_PROJECT_ORGANIZATION = 'SELECT_PROJECT_ORGANIZATION'
