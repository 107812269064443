import React, { FC, memo } from 'react'

import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminFunctionStringToggles from './AdminFunctionStringToggles'
import AdminResetServer from './AdminResetServer'
import AdminSingletons from './AdminSingletons/index'
import AdminStopRecalculating from './AdminStopRecalculating'
import AdminMailManager from 'Scenes/admin/AdminHome/AdminSettings/AdminMailManager'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

const AdminSettings: FC = () => {
	return (
		<NavBarAndMaterialTSX title={'Settings'}>
			<AdminSingletons />
			<AdminFunctionStringToggles />
			<AdminStopRecalculating />
			<AdminMailManager />
			<AdminResetServer />
		</NavBarAndMaterialTSX>
	)
}

export default memo(AdminSettings)
